import { useState, useEffect, useContext } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useQuery } from '@tanstack/react-query'
import axios from '../config/axios'
import Spinner from '../components/Spinner'
import parse from 'html-react-parser'
import classes from './statistikaSinglePage.module.css'
import Comments from '../components/Comments'
import prethodna from './assets/prethodna.png'
import sledeca from './assets/sledeca.png'
import dole from './assets/dole.png'
import gore from './assets/gore.png'
import vasipredlozistrelica from './assets/vasipredlozistrelica.png'
import React from 'react'
import ReactPlayer from 'react-player'
import AuthContext from '../context/AuthProvider'

const SERVER_URL = process.env.REACT_APP_SERVER_URL

const StatistikaSinglePage = () => {
  const { id } = useParams()
  const { auth } = useContext(AuthContext)

  const [data, setData] = useState({})
  const [previousPost, setPreviousPost] = useState({})
  const [nextPost, setNextPost] = useState({})
  const [threeBefore, setThreeBefore] = useState({})
  const [threeNext, setThreeNext] = useState({})
  const [chatImage, setChatImage] = useState()
  const [commentCounter, setCommentCounter] = useState({
    totalComments: 0,
    commentsLength: 0,
    answersLength: 0,
  })
  const [emailNotification, setEmailNotification] = useState(false)
  const [commentNotification, setCommentNotification] = useState(false)

  const fetchData = async () => {
    const { data } = await axios.get(`/statistics-post/${id}`)

    setData(data.post)
  }

  useEffect(() => {
    fetchData()
    fetchChatImage()
  }, [id])

  useEffect(() => {
    setEmailNotification(auth?.emailNotification)
    setCommentNotification(auth?.commentNotification)
  }, [])

  const fetchChatImage = async () => {
    const img = await axios.get(`/chat-image/statistic`)

    setChatImage(img.data)
  }

  useEffect(() => {
    fetchPreviosuCard()
    fetchNextCard()
    fetchThreeNextCard()
    fetchThreeBeforeCard()
  }, [data])

  const fetchPreviosuCard = async () => {
    // console.log(data._id);
    try {
      const previousPost = await axios.get(
        `/statistics-index?id=${data._id}&&index=-1`
      )
      setPreviousPost(previousPost.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchNextCard = async () => {
    try {
      // console.log(data._id);
      const nextCard = await axios.get(
        `/statistics-index?id=${data._id}&&index=+1`
      )
      setNextPost(nextCard.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchThreeNextCard = async () => {
    try {
      // console.log(data._id);
      const threenextCard = await axios.get(
        `/statistics-index?id=${data._id}&&index=+3`
      )

      setThreeNext(threenextCard.data)
    } catch (error) {
      console.log(error)
    }
  }

  const fetchThreeBeforeCard = async () => {
    try {
      // console.log(data._id);
      const threebeforecard = await axios.get(
        `/statistics-index?id=${data._id}&&index=-3`
      )

      setThreeBefore(threebeforecard.data)
    } catch (error) {
      console.log(error)
    }
  }

  const handleContextMenu = (event) => {
    event.preventDefault()
  }

  const handleEmailNotification = async (e) => {
    const { checked } = e.target

    setEmailNotification(checked)

    const payload = {
      emailNotification: checked,
      postId: id,
    }
    try {
      await axios
        .post(
          `${SERVER_URL}/user/setEmailNotification/${auth.email}`,
          payload,
          { withCredentials: false }
        )
        .then((res) => {
          window.location.reload()
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const handleCommentEmailNotification = async (e) => {
    const { checked } = e.target

    setCommentNotification(checked)

    const payload = {
      commentNotification: checked,
    }
    try {
      await axios
        .post(
          `${SERVER_URL}/user/setCommentNotification/${auth.email}`,
          payload,
          { withCredentials: false }
        )
        .then((res) => {
          window.location.reload()
        })
        .catch((error) => {
          console.error(error)
        })
    } catch (error) {
      console.error(error)
    }
  }

  const emailCheckedForPost =
    auth.emailNotificationPosts && auth.emailNotificationPosts.includes(id)
  const adminCommentOnly = data.adminCommentOnly ? data.adminCommentOnly : false

  return (
    <div className={classes.mainCenter}>
      {!data ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.content}>
          <div className={classes.title}>{data.title?.toUpperCase()}</div>

          <div className={classes.firstRow}>
            {data && data.featureVideo && data.featureVideo.url ? (
              <div className={classes.featureVideoDiv}>
                <ReactPlayer
                  url={`${SERVER_URL}/${data.featureVideo?.url}`}
                  playing={true} // Enables autoplay
                  muted={true} // Mutes the video
                  loop
                  width='337px'
                  height='267px'
                  onContextMenu={handleContextMenu}
                />
              </div>
            ) : (
              <img
                className={classes.featureImage}
                src={`${SERVER_URL}/${data.featureImage?.url}`}
              ></img>
            )}

            {data && data.marketingVideo && data.marketingVideo.url ? (
              <div className={classes.featureVideoDiv}>
                <a
                  className={classes.marketingImageATag}
                  href={data.marketingLink}
                  target='_blank'
                >
                  <ReactPlayer
                    url={`${SERVER_URL}/${data.marketingVideo?.url}`}
                    playing={true} // Enables autoplay
                    muted={true} // Mutes the video
                    loop
                    width='337px'
                    height='267px'
                    onContextMenu={handleContextMenu}
                  />
                </a>
              </div>
            ) : (
              <a
                className={classes.marketingImageATag}
                href={data.marketingLink}
                target='_blank'
              >
                <img
                  className={classes.marketingImage}
                  src={`${SERVER_URL}/${data.marketingImage?.url}`}
                ></img>
              </a>
            )}
            <a
              className={classes.marketingImageATag}
              href={chatImage?.link}
              target='_blank'
            >
              <img
                className={classes.marketingImage}
                src={`${SERVER_URL}/${chatImage?.image?.url}`}
              ></img>
            </a>
          </div>

          <div className={classes.navigationRow}>
            {previousPost && previousPost._id && (
              <a href={`/statistika/${previousPost._id}`}>
                <img className={classes.navigationImage} src={prethodna}></img>
              </a>
            )}

            {threeNext && threeNext._id && (
              <a href={`/statistika/${threeNext._id}`}>
                <img className={classes.navigationImage} src={gore}></img>
              </a>
            )}

            <a href={`/vaši_predlozi`}>
              <img
                className={classes.navigationImage}
                src={vasipredlozistrelica}
              ></img>
            </a>

            {threeBefore && threeBefore._id && (
              <a href={`/statistika/${threeBefore._id}`}>
                <img className={classes.navigationImage} src={dole}></img>
              </a>
            )}
            {nextPost && nextPost._id && (
              <a href={`/statistika/${nextPost._id}`}>
                <img className={classes.navigationImage} src={sledeca}></img>
              </a>
            )}
          </div>

          <div className={classes.contentRow}>
            {data &&
              data.items &&
              data.items.length > 0 &&
              data.items.map((item, index) => {
                if (item.type === 'text') {
                  return (
                    <div
                      key={index}
                      style={{
                        color: item.color,
                        fontWeight: item.bold ? 'bolder' : false,
                        fontStyle: item.italic ? 'italic' : false,
                        fontSize: item.fontSize ? `${item.fontSize}px` : '30px',
                        marginTop: item.marginTop,
                        marginBottom: item.marginBottom,
                      }}
                    >
                      {item.description}
                    </div>
                  )
                }
                if (item.type === 'video') {
                  // console.log(item);
                  return (
                    <video
                      width='720'
                      height='360'
                      style={{ marginBottom: '10px' }}
                      controls
                      controlsList='nodownload'
                    >
                      <source
                        src={`${SERVER_URL}/${item.video.url}`}
                        type='video/mp4'
                      />
                    </video>
                  )
                }
                if (item.type === 'img') {
                  return (
                    <a
                      href={item?.imageLink}
                      target='_blank'
                      className={classes.itemImageATag}
                    >
                      <img
                        className={classes.itemImage}
                        src={`${SERVER_URL}/${item.image?.url}`}
                      ></img>
                    </a>
                  )
                }
              })}
          </div>
          <div className={classes.comment}>K O M E N T A R I ( Š I ) </div>

          <div className={classes.textField}>
            {data.textField?.toUpperCase()}
          </div>

          <div className={classes.notificationsContainer}>
            <div className={classes.obavestenja}>
              <label htmlFor='obavestenja'>
                Želim da primam e-mail obaveštenja za sve promene na ovoj
                stranici
              </label>
              <div>
                <input
                  disabled={auth && Object.keys(auth).length < 1}
                  checked={emailCheckedForPost}
                  onChange={(e) => handleEmailNotification(e)}
                  type='checkbox'
                  name='obavestenja'
                  id='obavestenja'
                />
              </div>
            </div>
            <div className={classes.obavestenja}>
              <label htmlFor='commentObavestenje'>
                Želim da primam obaveštenja o odgovorima na komentar putem
                email-a.
              </label>
              <div>
                <input
                  disabled={auth && Object.keys(auth).length < 1}
                  checked={commentNotification}
                  onChange={(e) => handleCommentEmailNotification(e)}
                  type='checkbox'
                  name='commentObavestenje'
                  id='commentObavestenje'
                />
              </div>
            </div>
          </div>

          <div className={classes.commentStats}>
            <div>
              <span>Uk. br. komentara: </span>
              <span>{commentCounter.commentsLength}</span>
            </div>
            <div>
              <span>Uk. br. odgovora: </span>
              <span>{commentCounter.answersLength}</span>
            </div>
            <div>
              <span>Uk. komentara: </span>
              <span>{commentCounter.totalComments}</span>
            </div>
          </div>
        </div>
      )}
      <Comments
        adminComment={adminCommentOnly}
        commentType={'statistika'}
        user={auth}
        id={id}
        title={data.title}
        setCommentCounter={setCommentCounter}
      />
    </div>
  )
}

export default StatistikaSinglePage
