import React from "react";

const Igra = ({igra, procenat, background, color}) => {

    const formatiranProcenat = () => {
        if(procenat < 0) {
            return 0;
        } else if (procenat > 100) {
            return 100;
        } else {
            return procenat;
        }
    }
    return(
    <div style={{marginRight:"20px",marginTop:"30px", border:"3px solid black",height:"50px", minWidth:"80px", textAlign:"center", display:"flex", flexDirection:"column"}}>
        <div style={{backgroundColor: background, height:"50%", display:"flex", justifyContent:"center", alignItems:"center", color:color, fontWeight: "900"}}>{igra}</div>
        <div style={{height:"50%", display:"flex", justifyContent:"center", alignItems:"center"}}><p style={{fontWeight:"900", color:"black"}}>{formatiranProcenat()} {isNaN(procenat) ? null : "%"}</p></div>
    </div>
    )

}

export default Igra;