import classes from './navigation.module.css'
import { useContext } from 'react'
import AuthContext from '../context/AuthProvider'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCaretRight, faComments } from '@fortawesome/free-solid-svg-icons'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { NavLink } from 'react-router-dom'
import facebookIcon from '../icons/facebook.svg'
import instagramIcon from '../icons/instagram.svg'
import youtubeIcon from '../icons/youtube.svg'
import telegramIcon from '../icons/telegram.svg'
import tiktokIcon from '../icons/tiktok.svg'
import { Button } from 'antd'

const Navigation = () => {
  const { auth } = useContext(AuthContext)
  return (
    <div className={classes.container}>
      <nav>
        <ul className={classes.navUl}>
          <li className={classes.navLi}>
            <NavLink
              className={(navData) => (navData.isActive ? classes.active : '')}
              to='/?page=1'
            >
              <FontAwesomeIcon icon={faHouse} />
            </NavLink>
          </li>
          <li className={classes.navLi}>
            <NavLink
              className={(navData) => (navData.isActive ? classes.active : '')}
              to='/o_nama'
            >
              O nama
            </NavLink>
          </li>
          <li className={classes.navLi}>
            <NavLink
              className={(navData) => (navData.isActive ? classes.active : '')}
              to='/kontakt'
            >
              Kontakt
            </NavLink>
          </li>
          <li className={classes.navLi}>
            <NavLink
              className={(navData) => (navData.isActive ? classes.active : '')}
              to='/saradnja'
            >
              Saradnja
            </NavLink>
          </li>
          {Object.keys(auth).length === 0 ? (
            <li className={classes.navLi}>
              <NavLink
                className={(navData) =>
                  navData.isActive ? classes.active : ''
                }
                to='/registracija_i_logovanje'
              >
                Registracija-Logovanje
              </NavLink>
            </li>
          ) : (
            <li className={classes.navLi}>
              <NavLink
                className={(navData) =>
                  navData.isActive ? classes.active : ''
                }
                to='/moj_nalog'
              >
                Moj nalog
              </NavLink>
            </li>
          )}
          <li className={classes.navLi}>
            <NavLink
              className={(navData) => (navData.isActive ? classes.active : '')}
              to='/18+'
            >
              18+
            </NavLink>
          </li>

          <li className={classes.navLi}>
            <a
              href='https://www.facebook.com/groups/238872744699144/'
              target='_blank'
            >
              <img
                src={facebookIcon}
                alt='facebookIcon'
                className={classes.socialIcons}
              />
            </a>
          </li>
          <li className={classes.navLi}>
            <a
              href='https://www.instagram.com/betkobet_/?igshid=OTk0YzhjMDVlZA%3D%3D'
              target='_blank'
            >
              <img
                src={instagramIcon}
                alt='instagramIcon'
                className={classes.socialIcons}
              />
            </a>
          </li>
          <li className={classes.navLi}>
            <a
              href='https://www.youtube.com/@betkotip-nm4yk/featured'
              target='_blank'
            >
              <img
                src={youtubeIcon}
                alt='youtubeIcon'
                className={classes.socialIcons}
              />
            </a>
          </li>
          <li className={classes.navLi}>
            <a href='https://t.me/+noxLV5bw8eM0MWE0' target='_blank'>
              <img
                src={telegramIcon}
                alt='telegramIcon'
                className={classes.socialIcons}
              />
            </a>
          </li>
          <li className={classes.navLi}>
            <a
              href='https://www.tiktok.com/@betkobet?_t=8fNNdY5mMWT&_r=1'
              target='_blank'
            >
              <img
                src={tiktokIcon}
                alt='tiktokIcon'
                className={classes.socialIcons}
              />
            </a>
          </li>
          <li className={classes.navLi}>
            <NavLink
              className={(navData) => (navData.isActive ? classes.active : '')}
              to='/politika-privatnosti'
            >
              Politika privatnosti
            </NavLink>
          </li>
        </ul>
      </nav>
    </div>
  )
}

export default Navigation
