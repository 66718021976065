import { useNavigate } from 'react-router-dom'
import TwoButtons from './TwoButtons'
import Navigation from './Navigation'
import InfoNav from './InfoNav'
import classes from './header.module.css'
import logo from './assets/logo.png'
import { Button } from 'antd'

const Header = () => {
  const navigate = useNavigate()

  return (
    <>
      <div className={classes.container}>
        <div className={classes.left}>
          <button
            className={classes.podesavanja}
            onClick={() => navigate('/podešavanja_izgleda_sajta')}
          >
            Podesi izgled sajta
          </button>
          <button
            className={classes.pokreniKalkulatore}
            onClick={() => navigate('/pokreni_kalkulatore')}
          >
            Pokreni kalkulatore
          </button>
        </div>
        {/* <div className={classes.containerlogo}>
          <div className={classes.logoimage}>
            <img src={logo}></img>
          </div>
        </div> */}
        <Navigation />
      </div>
      <TwoButtons />
      <InfoNav />
    </>
  )
}

export default Header
