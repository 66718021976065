import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext } from "react";
import AuthContext from "../context/AuthProvider";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import useLogout from "../hooks/useLogout";

const RequireAuth = ({ allowedRoles, adminPanel }) => {
  const { auth } = useContext(AuthContext);
  const location = useLocation();
  const axiosPrivate = useAxiosPrivate();
  const logout = useLogout();
  const userEmail = auth?.email;

  const getUserData = async (userEmail) => {
    const { data } = await axiosPrivate.get(`/user/getPaidDate/${userEmail}`);
    return data;
  };

  const refreshPage = () => {
    window.location.reload();
  };

  const userDataQuery = useQuery({
    queryKey: ["userData", userEmail],
    queryFn: () => getUserData(userEmail),
    staleTime: 0,
    refetchInterval: 1000 * 60 * 60 * 12,
    // onSuccess: () => {
    //   console.log("sada sam uspesan");
    // },
    onError: (error) => {
      console.log("error: ", error);
      logout();
      refreshPage();
      <Navigate
        to="/registracija_i_logovanje"
        state={{ from: location }}
        replace
      />;
    },
  });

  if (
    userDataQuery.isError &&
    userDataQuery.error.response.status === 401
    // || userDataQuery.error.response.status === 403
  ) {
    logout();
    refreshPage();
    <Navigate
      to="/registracija_i_logovanje"
      state={{ from: location }}
      replace
    />;
  }

  return (auth?.roles?.find((role) => allowedRoles?.includes(role)) || (adminPanel === true && auth.moderator === true)) ? (
    <Outlet />
  ) : auth?.accessToken ? (
    <Navigate to="/neovlašćen" state={{ from: location }} replace />
  ) : (
    <Navigate
      to="/registracija_i_logovanje"
      state={{ from: location }}
      replace
    />
  );
};
export default RequireAuth;
