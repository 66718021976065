import classes from "./mojNalog.module.css";
import useLogout from "../hooks/useLogout";
import { useNavigate, Link } from "react-router-dom";
import { useState, useContext, useEffect } from "react";
import AuthContext from "../context/AuthProvider";
import useAxiosPrivate from "../hooks/useAxiosPrivate";
import { useQuery } from "@tanstack/react-query";
import jwt_decode from "jwt-decode";
import { format } from "date-fns";
import Spinner from "../components/Spinner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-regular-svg-icons";
import { notification } from "antd";
import { MdEdit, MdSaveAs } from "react-icons/md";
import axios from "../config/axios";

const MojNalog = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL

  const [createdAt, setCreatedAt] = useState(0);
  const [paidToDate, setPaidToDate] = useState(0);
  const [changeTeam, setChangeTeam] = useState(false)
  const [changeUsername, setChangeUsername] = useState(false)
  const [teamValue, setTeamValue] = useState({})
  const [usernameValue, setUserNameValue] = useState('')

  const [allTeams, setAllTeams] = useState([])

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useContext(AuthContext);
  const logout = useLogout();
  const navigate = useNavigate();

  let email;
  if (auth?.accessToken) {
    const token = auth?.accessToken;
    const decoded = jwt_decode(token);
    email = decoded?.UserInfo?.email;
  }

  const fetchTeams = async () => {
    try {
      await axios.get(`/getAllTeams`, { withCredentials: false }).then(res => {
        if (res.status === 200) {
          setAllTeams(res.data.data)
        }
      }).catch(error => {
        console.error(error)
      })
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    fetchTeams()

    return () => { }
  }, [])

  const signout = async () => {
    await logout();
    navigate("/");
  };

  const getUserInfo = async () => {
    const { data } = await axiosPrivate.get(`/user/${email}`);
    // console.log("data: ", data);
    setCreatedAt(format(new Date(data?.createdAt), "dd-MM-yyyy"));
    setPaidToDate(format(new Date(data?.paidToDate), "dd-MM-yyyy"));
    if (data.team) {
      setTeamValue(data?.team)
    } else {
      setTeamValue({})
    }
    if (data.userName) {
      setUserNameValue(data.userName)
    } else {
      setUserNameValue('')
    }
    return data;
  };

  const userInfoQuery = useQuery({
    queryKey: ["users"],
    queryFn: getUserInfo,
    // staleTime: 0,
    // refetchInterval: 1000 * 60 * 60 * 12,
  });

  if (userInfoQuery.isError) {
    navigate("/registracija_i_logovanje");
  }

  const handleChangeTeam = (e) => {
    const { value } = e.target

    setTeamValue(value)
  }

  const handleUpdateTeam = async (e) => {
    const { value } = e.target
    if (value === '' || value === 'none' || !value) {
      notification.error({
        message: 'Izaberite tim.',
        placement: 'topRight'
      })
      return
    }

    const payload = {
      team: value
    }
    try {
      await axiosPrivate.post(`/user/setUserTeam/${email}`, payload, { withCredentials: false }).then(res => {
        if (res.status === 201) {

          setChangeTeam(false)
          setTimeout(() => {
            window.location.reload()
          }, 300)

          // notification
          notification.success({
            message: 'Uspešno promenjen tim.',
            placement: 'topRight'
          })
        }
      }).catch(error => {
        console.error(error)
        // notification
        notification.error({
          message: error.response.data.message || 'Error while updating user.',
          placement: 'topRight'
        })
      })
    } catch (error) {
      console.error(error)
      // notification
      notification.error({
        message: error.response.data.message || 'Error while updating user.',
        placement: 'topRight'
      })
    }

  }

  const onChangeUsername = (e) => {
    const { value } = e.target
    if (value.includes(' ')) {
      return
    }
    setUserNameValue(e.target.value)
  }

  const handleUpadateUserName = async (e) => {
    if (!usernameValue || usernameValue === '') {
      notification.error({
        message: "Unesite username",
        placement: 'topRight'
      })
      return
    }

    const payload = {
      userName: usernameValue
    }

    try {
      await axiosPrivate.post(`/user/setUserName/${email}`, payload, { withCredentials: false }).then(res => {
        if (res.status === 201) {

          setChangeUsername(false)
          setTimeout(() => {
            window.location.reload()
          }, 300)

          // notification
          notification.success({
            message: 'Uspešno promenjen username.',
            placement: 'topRight'
          })
        }
      }).catch(error => {
        console.error(error)
        // notification
        notification.error({
          message: error.response.data.message || 'Error while updating user.',
          placement: 'topRight'
        })
      })
    } catch (error) {
      console.error(error)
      // notification
      notification.error({
        message: error.response.data.message || 'Error while updating user.',
        placement: 'topRight'
      })
    }
  }

  const profileImage = userInfoQuery?.data?.team ? `${SERVER_URL}/${userInfoQuery?.data?.team?.teamImage?.url}` : '../no_team_image.png'

  return (
    <div className={classes.mainCenter}>
      {userInfoQuery.isLoading ? (
        <Spinner />
      ) : (
        <>
          <h1>Informacije o Korisniku</h1>
          <div className={classes.container}>
            <div className={classes.left}>
              <div className={classes.profileImageContainer}>
                <img
                  src={profileImage}
                  alt={`Profile picture`}
                />
              </div>
            </div>
            <div className={classes.right}>
              <div className={`${classes.single} ${classes.changeUsername}`}>
                <div>
                  <span>Korisnik:</span>
                </div>

                <div className={classes.userNameContainer}>
                  {changeUsername ? (
                    <input
                      className={classes.userNameField}
                      type='text'
                      name='userName'
                      value={usernameValue}
                      onChange={(e) => onChangeUsername(e)}
                    />
                  ) : (
                    <span style={{ color: '#1b63de' }}>{userInfoQuery?.data?.userName}</span>
                  )}

                </div>

                <div className={classes.changeTeamButtonContainer}>
                  {changeUsername ? (
                    <button className={classes.changeTeamButton} onClick={(e) => handleUpadateUserName(e)}>
                      {/* <MdSaveAs /> */}
                      Sačuvaj
                    </button>
                  ) : (
                    <button className={classes.changeTeamButton} onClick={() => setChangeUsername(true)}>
                      {/* <MdEdit /> */}
                      Izmeni
                    </button>
                  )}
                </div>
              </div>
              <div className={classes.single}>
                Email:
                <span>{userInfoQuery?.data?.email}</span>
              </div>
              <div className={classes.single}>
                Registrovan od:
                <span>{createdAt}</span>
              </div>
              <div className={classes.single}>
                Placeno do:
                <span>
                  {userInfoQuery?.data?.status === "Admin" ||
                    userInfoQuery?.data?.status === "Vip"
                    ? "Neograničeno"
                    : paidToDate}
                </span>
              </div>
              <div className={classes.single}>
                Status:
                <span>{userInfoQuery?.data?.status}</span>
              </div>
              <div
                onClick={() => navigate("/promeni_lozinku")}
                className={classes.singleLastChild}
              >
                Promeni Lozinku
              </div>

              <div className={`${classes.single} ${classes.chooseTeam}`}>
                <div>
                  <span>Navijač tima:</span>
                </div>
                <div className={classes.changeTeamButtonContainer}>
                  {changeTeam ? (
                    <button className={classes.changeTeamButton} onClick={(e) => setChangeTeam(false)}>
                      {/* <MdSaveAs /> */}
                      Otkaži
                    </button>
                  ) : (
                    <button className={classes.changeTeamButton} onClick={() => setChangeTeam(true)}>
                      {/* <MdEdit /> */}
                      Izmeni
                    </button>
                  )}
                </div>
              </div>
              <div className={classes.single}>
                <div className={classes.selectContainer}>
                  {changeTeam ? (
                    <select value={teamValue._id || ''} onChange={(e) => handleUpdateTeam(e)} className={classes.selectField}>
                      <option value={''}>Izaberi tim</option>
                      {allTeams && allTeams.length > 0 && allTeams.map((item, index) => {
                        return (
                          <option key={index} value={item._id}>{item.name}</option>
                        )
                      })}
                    </select>
                  ) : (
                    <span style={{ color: '#1b63de' }}>{userInfoQuery?.data?.team?.name}</span>
                  )}
                </div>
              </div>

              <button className={classes.btn} onClick={() => signout()}>
                Izloguj se
              </button>
            </div>
          </div>
          {(userInfoQuery?.data?.status === "Admin" || userInfoQuery?.data?.moderator === true) && (
            <div className={classes.admin}>
              <Link to="/betko-admin">Idi na admin panel</Link>
            </div>
          )}
        </>
      )
      }
    </div >
  );
};

export default MojNalog;
