import { useState, useContext } from "react";
import { useMutation } from "@tanstack/react-query";
import { useNavigate, useLocation, Link } from "react-router-dom";
import axios from "../config/axios";
import AuthContext from "../context/AuthProvider";
import classes from "./auth.module.css";

const Auth = () => {
  const { setAuth, login, setLogin } = useContext(AuthContext);
  const [email, setEmail] = useState("");
  const [userName, setUserName] = useState('')
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [checked, setChecked] = useState(false);
  const [checkedTerms, setCheckedTerms] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoginError, setIsLoginError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");

  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const rp =
    /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/;
  const validEmail = re.test(email);
  const validPassword = rp.test(password);
  const validLoginEmail = re.test(loginEmail);
  const validLoginPassword = rp.test(loginPassword);

  const navigate = useNavigate();
  const location = useLocation();
  const from = location.state?.from?.pathname || "/";

  const handleRegistration = (e) => {
    e.preventDefault();

    if (!userName) {
      setIsError(true)
      setErrorMessage('Username polje je obavezno')
    }
    if (validEmail && validPassword && checked && userName && checkedTerms) {
      setIsError(false);
      setErrorMessage("");
      registerMutation.mutate({ userName, email, password });
    }
    if (!checked) {
      setIsError(true);
      setErrorMessage("Niste potvrdili broj godina");
    }
    if (!checkedTerms) {
      setIsError(true)
      setErrorMessage('Niste potvrdili opšte uslove i politiku privatnosti')
    }
    if (password !== confirmPassword) {
      setIsError(true);
      setErrorMessage("Lozinke se ne podudaraju");
    }
    if (!validPassword) {
      setIsError(true);
      setErrorMessage(
        "Lozinka mora biti minimum 8 karaktera, sadržati bar jedno veliko slovo, malo slovo, broj i specijalan karakter"
      );
    }
    if (!validEmail) {
      setIsError(true);
      setErrorMessage("Email nije ispravan");
    }
  };
  const handleLogin = (e) => {
    e.preventDefault();
    if (validLoginEmail && validLoginPassword) {
      setIsLoginError(false);
      setLoginErrorMessage("");
      loginMutation.mutate({ email: loginEmail, password: loginPassword });
    }
    if (!validLoginPassword) {
      setIsLoginError(true);
      setLoginErrorMessage(
        "Lozinka mora biti minimum 8 karaktera, sadržati bar jedno veliko slovo, malo slovo, broj i specijalan karakter"
      );
    }
    if (!validLoginEmail) {
      setIsLoginError(true);
      setLoginErrorMessage("Email nije ispravan");
    }
  };

  const registerMutation = useMutation({
    mutationFn: (userData) => {
      return axios.post("/register", userData);
    },
    onSuccess: () => {
      setLogin(true);
      // exact: true;
    },
    onError: (error) => {
      console.log(error)
      if (error.response.status === 410) {
        setIsError(true)
        setErrorMessage('Username već postoji')
      }
      if (error.response.status === 409) {
        setIsError(true);
        setErrorMessage("Ovaj email je već registrovan");
      }
      if (error.response.status === 401) {
        setIsError(true);
        setErrorMessage("Email nije registrovan");
      }
      if (error.response.status === 429) {
        setIsError(true);
        setErrorMessage(
          "Previse pokusaja logovanja, pokusajte opet za 60 sekundi."
        );
      }
    },
  });
  const loginMutation = useMutation({
    mutationFn: async (userData) => {
      const response = await axios.post("/auth", userData, {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
      });
      const accessToken = response?.data?.accessToken;
      const roles = response?.data?.roles;
      const paidToDate = new Date(response?.data?.paidToDate).getTime();
      const email = response?.data?.email;
      const _id = response?.data?._id
      const commentRestricted = response?.data?.commentRestricted
      const status = response?.data?.status
      const username = response?.data?.username
      const emailNotification = response?.data?.emailNotification
      const commentNotification = response?.data?.commentNotification
      const moderator = response?.data?.moderator
      const emailNotificationPosts = response?.data?.emailNotificationPosts
      setAuth({ email, accessToken, roles, paidToDate, _id, commentRestricted, status, username, emailNotification, commentNotification, moderator, emailNotificationPosts });
    },
    onSuccess: () => {
      navigate(from, { replace: true });
    },
    onError: (error) => {
      if (error.response.status === 401) {
        setIsLoginError(true);
        setLoginErrorMessage("Pogrešan email ili password.");
      }
      if (error.response.status === 429) {
        setIsLoginError(true);
        setLoginErrorMessage(
          "Previse pokusaja logovanja, pokusajte opet za 60 sekundi."
        );
      }
    },
  });

  return (
    <div className={classes.mainCenter}>
      <h2 className={classes.red}>
        UKOLIKO NISTE REGISTROVANI KLIKNITE NA "REGISTRACIJA"
      </h2>
      <div className={classes.container}>
        <div className={classes.main}>
          <input
            type="checkbox"
            className={classes.chk}
            // aria-hidden="true"
            checked={login}
            onChange={() => setLogin(!login)}
          />

          <div className={classes.signup}>
            <form>
              <label
                className={classes.label}
                htmlFor="chk"
                // aria-hidden="true"
                onClick={() => setLogin(false)}
              >
                Registracija
              </label>
              {isError && (
                <div className={classes.errorRegDiv}>{errorMessage}</div>
              )}
              <input
                className={classes.input}
                type='text'
                // name='username'
                placeholder="Username"
                required=''
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
              />
              <input
                className={classes.input}
                type="email"
                name="email"
                placeholder="Email"
                required=""
                onChange={(e) => setEmail(e.target.value)}
              />
              <input
                className={classes.input}
                type="password"
                name="txt"
                placeholder="Lozinka"
                required=""
                onChange={(e) => setPassword(e.target.value)}
              />
              <input
                className={classes.input}
                type="password"
                name="pswd"
                placeholder="Ponovi Lozinku"
                required=""
                onChange={(e) => setConfirmPassword(e.target.value)}
              />

              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '2px', padding: '0px 15px' }}>
                <label className={classes.labelChk} htmlFor="potvrda">
                  Potvrđujem da imam više od 18 godina
                </label>

                <input
                  className={classes.checkbox}
                  type="checkbox"
                  name="potvrda"
                  id="potvrda"
                  checked={checked}
                  onChange={() => setChecked((prev) => !prev)}
                />
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '2px', padding: '0px 15px' }}>
                <label className={classes.labelChk} htmlFor="opstiUslovi">
                  Saglasan sam i prihvatam opšte uslove i politiku privatnosti <Link style={{ color: 'rgb(180,0,255,1)' }} to='/opšti_uslovi' target='_blank'>(opšti uslovi i politika privatnosti)</Link> korišćenja  betkotip.com
                </label>

                <div style={{ display: 'block' }}>
                  <input
                    className={classes.checkbox}
                    type="checkbox"
                    name="opstiUslovi"
                    id="opstiUslovi"
                    checked={checkedTerms}
                    onChange={() => setCheckedTerms((prev) => !prev)}
                  />
                </div>
              </div>
              <button
                onClick={handleRegistration}
                className={classes.btn}
                disabled={registerMutation.isLoading}
              >
                Registracija
              </button>
            </form>
          </div>

          <div className={classes.login}>
            <form>
              <label
                className={classes.label}
                htmlFor="chk"
                // aria-hidden="true"
                onClick={() => setLogin(true)}
              >
                Logovanje
              </label>
              {isLoginError && (
                <div className={classes.errorLogDiv}>{loginErrorMessage}</div>
              )}
              <input
                className={classes.input}
                type="email"
                name="email"
                placeholder="Email"
                required=""
                onChange={(e) => setLoginEmail(e.target.value)}
              />
              <input
                className={classes.input}
                type="password"
                name="pswd"
                placeholder="Lozinka"
                required=""
                onChange={(e) => setLoginPassword(e.target.value)}
              />
              <div className={classes.zaboravljenaLozinka}>
                <p onClick={() => navigate("/zaboravio_sam_lozinku")}>
                  Zaboravio sam lozinku
                </p>
              </div>
              <button
                onClick={handleLogin}
                className={classes.btn}
                disabled={loginMutation.isLoading}
              >
                Login
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
