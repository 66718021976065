import React from "react";

const KategorijeIgre = ({title, background, color}) => {
    return(
        <div style={{backgroundColor: background, display:"flex", border:"3px solid black",  justifyContent:"center", alignItems:"center", width:"285px", height:"50px", marginTop:"30px", marginRight:"20px", fontWeight:"900"}}>
            <h3 style={{color:color, fontSize:"15px"}}>{title}</h3>
        </div>
    )
}

export default KategorijeIgre;