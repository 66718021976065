import Grupa1 from '../components/Grupa1'
import Grupa20 from '../components/Grupa20'
import classes from './fudbalSveIgre.module.css'
import Title from '../../components/titlefudbal'
import KategorijeIgre from '../../components/fudbalkategorijaigra'
import Igra from '../../components/igra'
import { useEffect, useState } from 'react'

let FudbalSveIgre = () => {
  const calculatorType = 'Fudbal - sve igre'

  let [B8, setB8] = useState()
  let [D8, setD8] = useState()
  let [F8, setF8] = useState()
  let [H8, setH8] = useState()
  let [J8, setJ8] = useState()
  let [L8, setL8] = useState()
  let [N8, setN8] = useState()
  let [P8, setP8] = useState()
  let [R8, setR8] = useState()
  let [T8, setT8] = useState()
  let [V8, setV8] = useState()
  let [X8, setX8] = useState()

  let BA43 = B8
  let BB43 = D8
  let BC43 = F8
  let BA44 = Number(1 / Number(BA43))
  let BB44 = Number(1 / Number(BB43))
  let BC44 = Number(1 / Number(BC43))
  let BD44 = Number(Number(BC44) + Number(BB44) + Number(BA44))
  let BE44 = Number((Number(BD44) - 1) / 3)
  let BA46 = Number(Number(BA44) - Number(BE44))
  let BA46Value = isNaN(BA46) ? '-' : BA46.toFixed(2)
  let BB46 = Number(Number(BB44) - Number(BE44))
  let BB46Value = isNaN(BB46) ? '-' : BB46.toFixed(2)
  let BC46 = Number(Number(BC44) - Number(BE44))
  let BC46Value = isNaN(BC46) ? '-' : BC46.toFixed(2)
  let BD46 = Number(Number(BC46) + Number(BB46) + Number(BA46)).toFixed(2)
  let BF46 = Number(Number(BB46) / 2).toFixed(2)
  let BA52 = Number(Number(BA46) + Number(BF46))
  let BB52 = Number(Number(BC46) + Number(BF46))
  let BA18 = H8
  let BB18 = J8
  let BC18 = L8
  let BD18 = N8
  let BE18 = P8
  let BF18 = R8
  let BG18 = T8
  let BA19 = Number(1 / Number(BA18))
  let BB19 = Number(1 / Number(BB18))
  let BC19 = Number(1 / Number(BC18))
  let BD19 = Number(1 / Number(BD18))
  let BE19 = Number(1 / Number(BE18))
  let BF19 = Number(1 / Number(BF18))
  let BG19 = Number(1 / Number(BG18))
  let BH19 = Number(
    Number(BA19) +
      Number(BB19) +
      Number(BC19) +
      Number(BD19) +
      Number(BE19) +
      Number(BF19) +
      Number(BG19)
  )
  let BI19 = Number(BH19 * 100 - 100)
  let BA20 = (BI19 * 0.08) / 100
  let BB20 = (BI19 * 0.22) / 100
  let BC20 = (BI19 * 0.22) / 100
  let BD20 = BD19 * 0.22
  let BE20 = BE19 * 0.13
  let BF20 = (BI19 * 0.07) / 100
  let BG20 = BG19 * 0.05
  let BH20 = Number(BA20 + BB20 + BC20 + BD20 + BE20 + BF20 + BG20) * 100
  let BI20 = Number((Number(BH20) - Number(BI19)) / 7)
  let I55 = null
  let J54 = null
  let J55 = null
  let BA21 = () => {
    if (I55 >= J54) {
      let result = BA20 - J55
      return isNaN(result) ? '-' : result
    } else {
      let result = BA20 + J55
      return isNaN(result) ? '-' : result
    }
  }

  let BB21 = () => {
    if (I55 >= J54) {
      let result = BB20 - J55
      return isNaN(J55) ? '-' : result
    } else {
      let result = BB20 + J55
      return isNaN(result) ? '-' : result
    }
  }

  let BC21 = () => {
    if (I55 >= J54) {
      let result = BC20 - J55
      return isNaN(result) ? '-' : result
    } else {
      let result = BC20 + J55
      return isNaN(result) ? '-' : result
    }
  }

  let BD21 = () => {
    if (I55 >= J54) {
      let result = BD20 - J55
      return isNaN(result) ? '-' : result
    } else {
      let result = BD20 + J55
      return isNaN(result) ? '-' : result
    }
  }

  let BE21 = () => {
    if (I55 >= J54) {
      let result = BE20 - J55
      return isNaN(result) ? '-' : result
    } else {
      let result = BE20 + J55
      return isNaN(result) ? '-' : result
    }
  }

  let BF21 = () => {
    if (I55 >= J54) {
      let result = BF20 - J55
      return isNaN(result) ? '-' : result
    } else {
      let result = BF20 + J55
      return isNaN(result) ? '-' : result
    }
  }

  let BG21 = () => {
    if (I55 >= J54) {
      let result = BG20 - J55
      return isNaN(result) ? '-' : result
    } else {
      let result = BG20 + J55
      return isNaN(result) ? '-' : result
    }
  }

  let BH21 =
    Number(
      Number(BA21()) +
        Number(BB21()) +
        Number(BC21()) +
        Number(BD21()) +
        Number(BE21()) +
        Number(BF21()) +
        Number(BG21())
    ) * 100

  let BA23 = Number(BA19) - Number(BA21())
  let BB23 = Number(BB19) - Number(BB21())
  let BC23 = Number(BC19) - Number(BC21())
  let BD23 = Number(BD19) - Number(BD21())
  let BE23 = Number(BE19) - Number(BE21())
  let BF23 = Number(BF19) - Number(BF21())
  let BG23 = Number(BG19) - Number(BG21())
  let BH23 = Number(BA23 + BB23 + BC23 + BD23 + BE23 + BF23 + BG23)
  let BI23 = () => {
    if (BH23 * 100 >= 100) {
      let result = Number(BH23 * 100 - 100) / 100
      return isNaN(result) ? '-' : result
    } else {
      let result = Number(100 - BH23 * 100) / 100
      return isNaN(result) ? '-' : result
    }
  }

  let BA24 = 1 / Number(BA23)
  let BB24 = 1 / Number(BB23)
  let BC24 = 1 / Number(BC23)
  let BD24 = 1 / Number(BD23)
  let BE24 = 1 / Number(BE23)
  let BF24 = 1 / Number(BF23)
  let BG24 = 1 / Number(BG23)
  let BI24 = Number(BI23()) / 7

  let BA26 = 0
  let BB26 = 1
  let BC26 = 2
  let BD26 = 3
  let BE26 = 4
  let BF26 = 5
  let BG26 = 6

  let BA29 = () => {
    let result
    if (BI24 - BA23 < 0) {
      result = -BI24 + BA23
    } else if (BH23 >= 1) {
      result = BI24 - BA23
    } else {
      result = BA23 + BI24
    }

    return result
  }

  let BB29 = () => {
    let result

    if (BI24 - BB23 < 0) {
      result = Math.abs(-BI24 + -(-BB23))
    } else if (BH23 >= 1) {
      // assuming BH23 is a decimal (0.00 to 1.00 representing 0% to 100%)
      result = BI24 - BB23
    } else {
      result = BB23 + BI24
    }

    return result
  }

  let BC29 = () => {
    let result

    if (BI24 - BC23 < 0) {
      result = -BI24 - -BC23 // Note: -- is equivalent to + in JavaScript
    } else if (BH23 >= 1.0) {
      // 100% is equivalent to 1.0 in a decimal representation
      result = BI24 - BC23
    } else {
      result = BC23 + BI24
    }

    return result
  }

  let BD29 = () => {
    let result
    if (BI24 - BD23 < 0) {
      result = -BI24 + BD23 // changed -- to + because -- is equivalent to addition in this context
    } else if (BH23 >= 1) {
      // assuming BH23 is a decimal (0.00 to 1.00 representing 0% to 100%)
      result = BI24 - BD23
    } else {
      result = BD23 + BI24
    }

    return result
  }

  let BE29 = () => {
    let result

    if (BI24 - BE23 < 0) {
      result = -BI24 + BE23 // Translated -- to + because -- is equivalent to addition in this context
    } else if (BH23 >= 1) {
      // Assuming BH23 is a decimal (0.00 to 1.00 representing 0% to 100%)
      result = BI24 - BE23
    } else {
      result = BE23 + BI24
    }
    return result
  }

  let BF29 = () => {
    let result

    if (BI24 - BF23 < 0) {
      result = -BI24 + BF23 // Translated -- to + because -- is equivalent to addition in this context
    } else if (BH23 >= 1) {
      // Assuming BH23 is a decimal (0.00 to 1.00 representing 0% to 100%)
      result = BI24 - BF23
    } else {
      result = BF23 + BI24
    }
    return result
  }

  let BG29 = () => {
    let result = 1 - BA29() - BB29() - BC29() - BD29() - BE29() - BF29()

    return result
  }

  let BH29 = Number(
    BA29() + BB29() + BC29() + BD29() + BE29() + BF29() + BG29()
  )

  let BA35 = 1 / V8
  let BB35 = 1 / X8
  let BC35 = Number(BA35 + BB35)
  let BD35 = Number(BC35) - 1
  let BE35 = Number(BD35) / 2

  let BA37 = Number(BA35 - BE35)
  let BB37 = Number(BB35 - BE35)

  let BA27 = Number(BA29()) * Number(BA26)
  let BB27 = Number(BB29()) * Number(BB26)
  let BC27 = Number(BC29()) * Number(BC26)
  let BD27 = Number(BD29()) * Number(BD26)
  let BE27 = Number(BE29()) * Number(BE26)
  let BF27 = Number(BF29()) * Number(BF26)
  let BG27 = Number(BG29()) * Number(BG26)

  let BA49 = Number(BA27 + BB27 + BC27 + BD27 + BE27 + BF27 + BG27)

  let BA56 = Number(BA49) * Number(BA52)
  let BB56 = Number(BA49) * Number(BB52)

  let BA57 = Number(Math.round(BA56))
  let BA57Value = isNaN(BA57) ? '-' : BA57
  let BB57 = Number(Math.round(BB56))
  let BB57Value = isNaN(BB57) ? '-' : BB57

  let BA59 = () => {
    if (BA56 >= BB56) {
      let result = Number(BA56 - BB56)
      return isNaN(result) ? '-' : result
    } else {
      let result = Number(BB56 - BA56)
      return isNaN(result) ? '-' : result
    }
  }

  let BA72 = Number(BA49 - BA59())
  let BB72 = Number(BA49 + BA59())

  let BA74 = Number(BA52 * BA72)
  let BB74 = Number(BA72 * BB52)

  let BA75 = Number(Math.round(BA74))
  let BA75Value = isNaN(BA75) ? '-' : BA75
  let BB75 = Number(Math.round(BB74))
  let BB75Value = isNaN(BB75) ? '-' : BB75

  let BA77 = Number(BB72 * BA52)
  let BB77 = Number(BB72 * BB52)

  let BA78 = Number(Math.round(BA77))
  let BA78Value = isNaN(BA78) ? '-' : BA78
  let BB78 = Number(Math.round(BB77))
  let BB78Value = isNaN(BB78) ? '-' : BB78

  let B10 = BA46
  let B10Value = isNaN(B10) ? '-' : Number(B10 * 100).toFixed(2)
  let D10 = BB46
  let D10Value = isNaN(D10) ? '-' : Number(D10 * 100).toFixed(2)
  let F10 = Number(BC46)
  let F10Value = isNaN(F10) ? '-' : Number(F10 * 100).toFixed(2)
  let H10 = Number(BA29())
  let H10Value = isNaN(H10) ? '-' : Number(H10 * 100).toFixed(2)
  let J10 = Number(BB29())
  let J10Value = isNaN(J10) ? '-' : Number(J10 * 100).toFixed(2)
  let L10 = Number(BC29())
  let L10Value = isNaN(L10) ? '-' : Number(L10 * 100).toFixed(2)
  let N10 = Number(BD29())
  let N10Value = isNaN(N10) ? '-' : Number(N10 * 100).toFixed(2)
  let P10 = Number(BE29())
  let P10Value = isNaN(P10) ? '-' : Number(P10 * 100).toFixed(2)
  let R10 = Number(BF29())
  let R10Value = isNaN(R10) ? '-' : Number(R10 * 100).toFixed(2)
  let T10 = Number(BG29())
  let T10Value = isNaN(T10) ? '-' : Number(T10 * 100).toFixed(2)
  let V10 = Number(BA37)
  let V10Value = isNaN(V10) ? '-' : Number(V10 * 100).toFixed(2)
  let X10 = Number(BB37)
  let X10Value = isNaN(X10) ? '-' : Number(X10 * 100).toFixed(2)

  let Z6 = B10
  let AB6 = D10
  let AD6 = F10
  let AF6 = Number(BA29() + BB29() + BC29())
  let AF6Value = isNaN(AF6) ? '-' : Number(AF6 * 100).toFixed(2)
  let AH6 = Number(100 - AF6 * 100) / 100
  let AH6Value = isNaN(AH6) ? '-' : Number(AH6 * 100).toFixed(2)

  let AJ6 = BA72.toFixed(2)
  let AJ6Value = isNaN(AJ6) ? '-' : AJ6
  let AJ8 = BA49.toFixed(2)
  let AJ8Value = isNaN(AJ8) ? '-' : AJ8
  let AJ9 = BB72.toFixed(2)
  let AJ9Value = isNaN(AJ9) ? '-' : AJ9

  let AP6 = BA75Value + ':' + BB75Value
  let AP6Value = isNaN(AP6) ? '-' : AP6
  let AP8 = BA57Value + ':' + BB57Value
  let AP8Value = isNaN(AP8) ? '-' : AP8
  let AP9 = BA78Value + ':' + BB78Value
  let AP9Value = isNaN(AP9) ? '-' : AP9

  let AT6 = isNaN(BA74)
    ? '-'
    : Number(BA74).toFixed(2) + ':' + Number(BB74).toFixed(2)
  let AT8 = isNaN(BA56)
    ? '-'
    : Number(BA56).toFixed(2) + ':' + Number(BB56).toFixed(2)
  let AT9 = isNaN(BA77)
    ? '-'
    : Number(BA77).toFixed(2) + ':' + Number(BB77).toFixed(2)

  let H18 = Number(B10 * 100)
  let H18Value = isNaN(H18) ? '-' : Number(H18).toFixed(2)
  let J18 = Number(D10 * 100)
  let J18Value = isNaN(J18) ? '-' : Number(J18).toFixed(2)
  let L18 = Number(F10 * 100)
  let L18Value = isNaN(L18) ? '-' : Number(L18).toFixed(2)

  let H21 = Number(Number(H18) + Number(J18))
  let H21Value = isNaN(H21) ? '-' : Number(H21).toFixed(2)
  let J21 = Number(Number(H18) + Number(L18)).toFixed(2)
  let J21Value = isNaN(J21) ? '-' : Number(J21).toFixed(2)
  let L21 = Number(Number(J18) + Number(L18)).toFixed(2)
  let L21Value = isNaN(L21) ? '-' : Number(L21).toFixed(2)

  let Z18 = () => {
    if (H18 >= L18) {
      let result = (Number(H18) * 77.5) / 100
      return isNaN(result) ? '-' : result.toFixed(2)
    } else {
      let result = (Number(H18) * 83.5) / 100
      return isNaN(result) ? '-' : result.toFixed(2)
    }
  }

  let AD18 = () => {
    if (L18 > H18) {
      let result = (Number(L18) * 77.5) / 100
      return isNaN(result) ? '-' : result.toFixed(2)
    } else {
      let result = (Number(L18) * 83.5) / 100
      return isNaN(result) ? '-' : result.toFixed(2)
    }
  }

  let AB18 = Number(Number(J18) + 15).toFixed(2)
  let AB18Value = isNaN(AB18) ? '-' : Number(100 - Z18() - AD18()).toFixed(2)

  let Z21 = Number(Number(Z18()) + Number(AB18))
  let Z21Value = isNaN(Z21) ? '-' : Number(Z21).toFixed(2)
  let AB21 = Number(Number(Z18()) + Number(AD18()))
  let AB21Value = isNaN(AB21) ? '-' : Number(AB21.toFixed(2))
  let AD21 = Number(Number(AB18) + Number(AD18()))
  let AD21Value = isNaN(AD21) ? '-' : Number(AD21.toFixed(2))

  let AR18 = () => {
    if (H18 >= L18) {
      let result = (H18 * 115.8) / 100
      return isNaN(result) ? '-' : result.toFixed(2)
    } else {
      let result = (L18 * 92.8) / 100
      return isNaN(result) ? '-' : result.toFixed(2)
    }
  }

  let AT18 = AB18 - 8
  let AT18value = isNaN(AT18) ? '-' : AT18.toFixed(2)

  let AV18 = () => {
    if (L18 > H18) {
      let result = (L18 * 115.8) / 100
      return isNaN(result) ? '-' : result.toFixed(2)
    } else {
      let result = (L18 * 92.8) / 100
      return isNaN(result) ? '-' : result.toFixed(2)
    }
  }

  let AR21 = Number(AR18()) + Number(AT18)
  let AR21Value = isNaN(AR18()) ? '-' : AR21.toFixed(2)
  let AT21 = Number(Number(AR18()) + Number(AV18())).toFixed(2)
  let AT21Value = isNaN(AT21) ? '-' : Number(AT21).toFixed(2)
  let AV21 = Number(Number(AT18) + Number(AV18()))
  let AV21Value = isNaN(AV21) ? '-' : Number(AV21).toFixed(2)

  let AR24 = Number(H21)
  let AR24Value = isNaN(AR24) ? '-' : Number(AR24).toFixed(2)
  let AT24 = Number(L21)
  let AT24Value = isNaN(AT24) ? '-' : Number(AT24).toFixed(2)

  let AR27 = Number(Z21)
  let AR27Value = isNaN(AR27) ? '-' : Number(AR27).toFixed(2)
  let AT27 = Number(AD21)
  let AT27Value = isNaN(AT27) ? '-' : Number(AT27).toFixed(2)

  let AR30 = Number(AR21)
  let AR30Value = isNaN(AR30) ? '-' : Number(AR30).toFixed(2)
  let AT30 = Number(AV21)
  let AT30Value = isNaN(AT30) ? '-' : Number(AT30).toFixed(2)

  let J41 = Number(X10)
  let J41Value = isNaN(J41) ? '-' : Number(Number(J41) * 100).toFixed(2)

  let H24 = Number((Number(Z18()) * Number(AR18())) / 100)
  let H24Value = isNaN(H24) ? '-' : Number(H24).toFixed(2)
  let J24 = Number((Number(AD18()) * Number(AV18())) / 100)
  let J24Value = isNaN(J24) ? '-' : Number(J24).toFixed(2)
  let L24 = Number(Number(H18) * Number(J41))
  let L24Value = isNaN(L24) ? '-' : Number(L24).toFixed(2)
  let N24 = Number(Number(L18) * Number(J41))
  let N24Value = isNaN(N24) ? '-' : Number(N24).toFixed(2)

  let H27 = Number((Number(H18) + Number(Z18())) / 2)
  let H27Value = isNaN(H27) ? '-' : Number(H27).toFixed(2)
  let J27 = Number((Number(J18) + Number(AB18)) / 2)
  let J27Value = isNaN(J27) ? '-' : Number(J27).toFixed(2)
  let L27 = Number((Number(L18) + Number(AD18())) / 2)
  let L27Value = isNaN(L27) ? '-' : Number(L27).toFixed(2)

  // POBEDA SA RAZLIKOM

  let Z24 = Number((Number(Z18()) * Number(AR18())) / 100)
  let Z24Value = isNaN(Z24) ? '-' : Number(Z24).toFixed(2)

  let AB24 = Number((Number(AD18()) * Number(AV18())) / 100)
  let AB24Value = isNaN(AB24) ? '-' : Number(AB24).toFixed(2)

  let H35 = Number(Number(H10) + Number(J10))
  let H35Value = isNaN(H35) ? '-' : Number(Number(H35) * 100).toFixed(2)

  let J35 = Number(Number(J10) + Number(L10))
  let J35Value = isNaN(J35) ? '-' : Number(Number(J35) * 100).toFixed(2)

  let L35 = Number(Number(L10) + Number(N10))
  let L35Value = isNaN(L35) ? '-' : Number(Number(L35) * 100).toFixed(2)

  let N35 = Number(Number(N10) + Number(P10))
  let N35Value = isNaN(N35) ? '-' : Number(Number(N35) * 100).toFixed(2)

  let P35 = Number(Number(P10) + Number(R10))
  let P35Value = isNaN(P35) ? '-' : Number(Number(P35) * 100).toFixed(2)

  let R35 = Number(Number(R10) + Number(T10) - 0.02)
  let R35Value = isNaN(R35) ? '-' : Number(Number(R35) * 100).toFixed(2)

  let T35 = Number(Number(H10) + Number(J10) + Number(L10))
  let T35Value = isNaN(T35) ? '-' : Number(Number(T35) * 100).toFixed(2)

  let V35 = Number(Number(J10) + Number(L10) + Number(N10))
  let V35Value = isNaN(V35) ? '-' : Number(Number(V35) * 100).toFixed(2)

  let X35 = Number(Number(L10) + Number(P10))
  let X35Value = isNaN(X35) ? '-' : Number(Number(X35) * 100).toFixed(2)

  let Z35 = Number(Number(N10) + Number(P10) + Number(R10))
  let Z35Value = isNaN(Z35) ? '-' : Number(Number(Z35) * 100).toFixed(2)

  let AB35 = Number(Number(P10) + Number(R10) + Number(T10) - 0.02)
  let AB35Value = isNaN(AB35) ? '-' : Number(Number(AB35) * 100).toFixed(2)

  let AD35 = Number(Number(H10) + Number(J10) + Number(L10) + Number(N10))
  let AD35Value = isNaN(AD35) ? '-' : Number(Number(AD35) * 100).toFixed(2)

  let AF35 = Number(Number(J10) + Number(L10) + Number(N10) + Number(P10))
  let AF35Value = isNaN(AF35) ? '-' : Number(Number(AF35) * 100).toFixed(2)

  let AH35 = Number(Number(L10) + Number(N10) + Number(P10) + Number(R10))
  let AH35Value = isNaN(AH35) ? '-' : Number(Number(AH35) * 100).toFixed(2)

  let AJ35 = Number(
    Number(N10) + Number(P10) + Number(R10) + Number(T10) - 0.02
  )
  let AJ35Value = isNaN(AJ35) ? '-' : Number(Number(AJ35) * 100).toFixed(2)

  let AL35 = Number(
    Number(H10) + Number(J10) + Number(L10) + Number(N10) + Number(P10)
  )
  let AL35Value = isNaN(AL35) ? '-' : Number(Number(AL35) * 100).toFixed(2)

  let AN35 = Number(
    Number(J10) + Number(L10) + Number(N10) + Number(P10) + Number(R10)
  )
  let AN35Value = isNaN(AN35) ? '-' : Number(Number(AN35) * 100).toFixed(2)

  let AP35 = Number(
    Number(L10) + Number(N10) + Number(P10) + Number(R10) + Number(T10) - 0.02
  )
  let AP35Value = isNaN(AP35) ? '-' : Number(Number(AP35) * 100).toFixed(2)

  let AR35 = Number(
    Number(L10) + Number(N10) + Number(P10) + Number(R10) + Number(T10)
  )
  let AR35Value = isNaN(AR35) ? '-' : Number(Number(AR35) * 100).toFixed(2)

  let AT35 = Number(Number(N10) + Number(P10) + Number(R10) + Number(T10))
  let AT35Value = isNaN(AT35) ? '-' : Number(Number(AT35) * 100).toFixed(2)

  let AV35 = Number(Number(P10) + Number(R10) + Number(T10))
  let AV35Value = isNaN(AV35) ? '-' : Number(Number(AV35) * 100).toFixed(2)

  let AX35 = Number(Number(R10) + Number(T10))
  let AX35Value = isNaN(AX35) ? '-' : Number(Number(AX35) * 100).toFixed(2)

  let H38 = T10
  let H38Value = isNaN(H38) ? '-' : Number(Number(H38) * 100).toFixed(2)

  let J38 = Number(Number(H38) / 3)
  let J38Value = isNaN(J38) ? '-' : Number(Number(J38) * 100).toFixed(2)

  let N38 = H10
  let N38Value = isNaN(H10) ? '-' : Number(Number(H10) * 100).toFixed(2)

  let P38 = J10
  let P38Value = isNaN(P38) ? '-' : Number(Number(P38) * 100).toFixed(2)

  let R38 = L10
  let R38Value = isNaN(R38) ? '-' : Number(Number(R38) * 100).toFixed(2)

  let T38 = N10
  let T38Value = isNaN(T38) ? '-' : Number(Number(T38) * 100).toFixed(2)

  let V38 = P10
  let V38Value = isNaN(V38) ? '-' : Number(Number(V38) * 100).toFixed(2)

  let X38 = R10
  let X38Value = isNaN(X38) ? '-' : Number(Number(X38) * 100).toFixed(2)

  let Z38 = Number(Number(H38) - Number(J38))
  let Z38Value = isNaN(Z38) ? '-' : Number(Number(Z38) * 100).toFixed(2)

  let H41 = V10
  let H41Value = isNaN(H41) ? '-' : Number(Number(H41) * 100).toFixed(2)

  let L41 = Number((Number(H41) * 36.94) / 100)
  let L41Value = isNaN(L41) ? '-' : Number(Number(L41) * 100).toFixed(2)

  let N41 = Number(1 - Number(L41))
  let N41Value = isNaN(N41) ? '-' : Number(Number(N41) * 100).toFixed(2)

  let P41 = Number(H41 * 49.37)
  let P41Value = isNaN(P41) ? '-' : Number(Number(P41)).toFixed(2)

  let R41 = Number(100 - Number(P41))
  let R41Value = isNaN(R41) ? '-' : Number(Number(R41)).toFixed(2)

  let H44 = B10
  let H44Value = isNaN(H44) ? '-' : Number(Number(H44) * 100).toFixed(2)

  let J44 = D10
  let J44Value = isNaN(J44) ? '-' : Number(Number(J44) * 100).toFixed(2)

  let L44 = F10
  let L44Value = isNaN(L44) ? '-' : Number(Number(L44) * 100).toFixed(2)

  // PP PG , DP PG, PP PGD, PP PGG, DP PGD, DP PGG

  let H47 = H44
  let H47Value = isNaN(H47) ? '-' : Number(Number(H47) * 100).toFixed(2)

  let J47 = L44
  let J47Value = isNaN(J47) ? '-' : Number(Number(J47) * 100).toFixed(2)

  let J50 = Number((Number(AR35) * 46.71) / 100)
  let J50Value = isNaN(J50) ? '-' : Number(Number(J50) * 100).toFixed(2)

  let L50 = Number((Number(AT35) * 24.35) / 100)
  let L50Value = isNaN(L50) ? '-' : Number(Number(L50) * 100).toFixed(2)

  let N50 = Number((Number(AV35) * 11.94) / 100)
  let N50Value = isNaN(N50) ? '-' : Number(Number(N50) * 100).toFixed(2)

  let P50 = Number((Number(AL35) * 76.84) / 100)
  let P50Value = isNaN(P50) ? '-' : Number(Number(P50) * 100).toFixed(2)

  let R50 = Number((Number(T35) * 189.2) / 100)
  let R50Value = isNaN(R50) ? '-' : Number(Number(R50) * 100).toFixed(2)

  let T50 = Number((Number(J35) * 148.96) / 100)
  let T50Value = isNaN(T50) ? '-' : Number(T50 * 100).toFixed(2)

  let V50 = Number((Number(V35) * 111.3) / 100)
  let V50Value = isNaN(V50) ? '-' : Number(Number(V50) * 100).toFixed(2)

  let X50 = Number((Number(L35) * 71.55) / 100)
  let X50Value = isNaN(X50) ? '-' : Number(Number(X50) * 100).toFixed(2)

  let Z50 = Number((Number(N38) * 448.5) / 100)
  let Z50Value = isNaN(Z50) ? '-' : Number(Number(Z50) * 100).toFixed(2)

  let H50 = Number(1 - Number(Z50))
  let H50Value = isNaN(H50) ? '-' : Number(Number(H50) * 100).toFixed(2)

  let AB50 = Number(Number(P50) - Number(Z50))
  let AB50Value = isNaN(AB50) ? '-' : Number(Number(AB50) * 100).toFixed(2)

  let AD50 = Number((Number(R38) * 98.42) / 100)
  let AD50Value = isNaN(AD50) ? '-' : Number(Number(AD50) * 100).toFixed(2)

  let AF50 = Number((Number(T38) * 42.7) / 100)
  let AF50Value = isNaN(AF50) ? '-' : Number(Number(AF50) * 100).toFixed(2)

  let AH50 = Number(1 - Number(AB50))
  let AH50Value = isNaN(AH50) ? '-' : Number(Number(AH50) * 100).toFixed(2)

  let AJ50 = Number(1 - Number(AB50))
  let AJ50Value = isNaN(AJ50) ? '-' : Number(Number(AJ50) * 100).toFixed(2)

  let AL50 = Z50
  let AL50Value = isNaN(AL50) ? '-' : Number(Number(AL50) * 100).toFixed(2)

  let J53 = Number((Number(AR35) * 61.35) / 100)
  let J53Value = isNaN(J53) ? '-' : Number(Number(J53) * 100).toFixed(2)

  let L54 = Number((Number(AR35) * 61.35) / 100)
  let L53 = L54
  let L53Value = isNaN(L53) ? '-' : Number(Number(L53) * 100).toFixed(2)

  let N53 = Number((Number(AV35) * 23.58) / 100)
  let N54 = N53
  let N53Value = isNaN(N53) ? '-' : Number(Number(N53) * 100).toFixed(2)

  let P53 = Number((Number(AL35) * 63.17) / 100)
  let P54 = P53
  let P53Value = isNaN(P53) ? '-' : Number(Number(P53) * 100).toFixed(2)

  let R53 = Number((Number(T35) * 170.57) / 100)
  let R54 = R53
  let R53Value = isNaN(R53) ? '-' : Number(Number(R53) * 100).toFixed(2)

  let T53 = Number((Number(J35) * 146.74) / 100)
  let T54 = T53
  let T53Value = isNaN(T53) ? '-' : Number(Number(T53) * 100).toFixed(2)

  let V53 = Number((Number(V35) * 117.65) / 100)
  let V54 = V53
  let V53Value = isNaN(V53) ? '-' : Number(Number(V53) * 100).toFixed(2)

  let X53 = Number((Number(L35) * 88.32) / 100)
  let X54 = X53
  let X53Value = isNaN(X53) ? '-' : Number(Number(X53) * 100).toFixed(2)

  let Z53 = Number((Number(N38) * 324.16) / 100)
  let Z54 = Z53
  let Z53Value = isNaN(Z53) ? '-' : Number(Number(Z53) * 100).toFixed(2)

  let H53 = Number(1 - Z53)
  let H54 = H53
  let H53Value = isNaN(H53) ? '-' : Number(Number(H53) * 100).toFixed(2)

  let AB53 = Number((Number(P38) * 198.96) / 100)
  let AB54 = AB53
  let AB53Value = isNaN(AB53) ? '-' : Number(Number(AB53) * 100).toFixed(2)

  let AD53 = Number((R38 * 110.36) / 100)
  let AD54 = AD53
  let AD53Value = isNaN(AD53) ? '-' : Number(Number(AD53) * 100).toFixed(2)

  let AF53 = Number((Number(T38) * 64.65) / 100)
  let AF54 = AF53
  let AF53Value = isNaN(AF53) ? '-' : Number(Number(AF53) * 100).toFixed(2)

  let AH53 = Number(1 - AB53)
  let AH54 = AH53
  let AH53Value = isNaN(AH54) ? '-' : Number(Number(AH53) * 100).toFixed(2)

  let AJ53 = Number(1 - AB53)
  let AJ54 = AH53
  let AJ53Value = isNaN(AJ54) ? '-' : Number(Number(AJ53) * 100).toFixed(2)

  let AL53 = Z53
  let AL54 = AL53
  let AL53Value = isNaN(AL53) ? '-' : Number(Number(AL53) * 100).toFixed(2)

  let H57 = Number(Number(H50) * Number(AR35))
  let H57Value = isNaN(H57) ? '-' : Number(Number(H57) * 100).toFixed(2)

  let J57 = Number(Number(H50) * Number(AH6))
  let J57Value = isNaN(J57) ? '-' : Number(Number(J57) * 100).toFixed(2)

  let L57 = Number(Number(H50) * Number(H54))
  let L57Value = isNaN(L57) ? '-' : Number(Number(L57) * 100).toFixed(2)

  let P57 = Number(Number(H50) * Number(J53))
  let P57Value = isNaN(P57) ? '-' : Number(Number(P57) * 100).toFixed(2)

  let T57 = Number(Number(H50) * Number(L54))
  let T57Value = isNaN(T57) ? '-' : Number(Number(T57) * 100).toFixed(2)

  let X57 = Number(Number(H50) * Number(P54))
  let X57Value = isNaN(X57) ? '-' : Number(Number(X57) * 100).toFixed(2)

  let AB57 = Number(Number(P50) * Number(R54))
  let AB57Value = isNaN(AB57) ? '-' : Number(Number(AB57) * 100).toFixed(2)

  let AF57 = Number(Number(H50) * Number(T54))
  let AF57Value = isNaN(AF57) ? '-' : Number(Number(AF57) * 100).toFixed(2)

  let AJ57 = Number(Number(H50) * Number(V54))
  let AJ57Value = isNaN(AJ57) ? '-' : Number(Number(AJ57) * 100).toFixed(2)

  let AN57 = Number(Number(H50) * Number(X54))
  let AN57Value = isNaN(AN57) ? '-' : Number(Number(AN57) * 100).toFixed(2)

  let AR57 = Number(Number(H50) * Number(X53))
  let AR57Value = isNaN(AR57) ? '-' : Number(Number(AR57) * 100).toFixed(2)

  let AV57 = Number(Number(P50) * Number(J53))
  let AV57Value = isNaN(AV57) ? '-' : Number(Number(AV57) * 100).toFixed(2)

  let H60 = Number(Number(P50) * Number(P54))
  let H60Value = isNaN(H60) ? '-' : Number(Number(H60) * 100).toFixed(2)

  let L60 = Number(
    Number(P50) *
      Number(Number(Z54) + Number(AB54) + Number(AD54) + Number(AF54))
  )
  let L60Value = isNaN(L60) ? '-' : Number(Number(L60) * 100).toFixed(2)

  let P60 = Number(Number(P50) * Number(T54))
  let P60Value = isNaN(P60) ? '-' : Number(Number(P60) * 100).toFixed(2)

  let T60 = P50
  let T60Value = isNaN(T60) ? '-' : Number(Number(T60) * 100).toFixed(2)

  let X60 = Number(Number(P50) * Number(X54))
  let X60Value = isNaN(X60) ? '-' : Number(Number(X60) * 100).toFixed(2)

  let AB60 = Number(Number(J50) * Number(H54))
  let AB60Value = isNaN(AB60) ? '-' : Number(Number(AB60) * 100).toFixed(2)

  let AF60 = Number(Number(J50) * Number(J53))
  let AF60Value = isNaN(AF60) ? '-' : Number(Number(AF60) * 100).toFixed(2)

  let AJ60 = Number(Number(J50) * Number(L54))
  let AJ60Value = isNaN(AJ60) ? '-' : Number(Number(AJ60) * 100).toFixed(2)

  let AN60 = Number(Number(J50) * Number(N54))
  let AN60Value = isNaN(AN60) ? '-' : Number(Number(AN60) * 100).toFixed(2)

  let AR60 = Number(Number(J50) * Number(P54))
  let AR60Value = isNaN(AR60) ? '-' : Number(Number(AR60) * 100).toFixed(2)

  let AV60 = Number(Number(J50) * Number(X54))
  let AV60Value = isNaN(AV60) ? '-' : Number(Number(AV60) * 100).toFixed(2)

  let H63 = Number(Number(R50) * Number(J53))
  let H63Value = isNaN(H63) ? '-' : Number(Number(H63) * 100).toFixed(2)

  let L63 = Number(Number(R50) * Number(P54))
  let L63Value = isNaN(L63) ? '–' : Number(Number(L63) * 100).toFixed(2)

  let P63 = Number(Number(R50) * Number(R54))
  let P63Value = isNaN(P63) ? '-' : Number(Number(P63) * 100).toFixed(2)

  let T63 = Number(
    Number(R50) *
      Number(Number(Z54) + Number(AB54) + Number(AD54) + Number(AF54))
  )
  let T63Value = isNaN(T63) ? '-' : Number(Number(T63) * 100).toFixed(2)

  let X63 = Number(Number(R50) * Number(Number(T54)))
  let X63Value = isNaN(X63) ? '-' : Number(Number(X63) * 100).toFixed(2)

  let AB63 = Number(Number(R54) * Number(V54))
  let AB63Value = isNaN(AB63) ? '-' : Number(Number(AB63) * 100).toFixed(2)

  let AF63 = Number(Number(R50) * Number(X54))
  let AF63Value = isNaN(AF63) ? '-' : Number(Number(AF63) * 100).toFixed(2)

  let AJ63 = Number(Number(T50) * Number(H54))
  let AJ63Value = isNaN(AJ63) ? '-' : Number(Number(AJ63) * 100).toFixed(2)

  let AN63 = Number(Number(T50) * Number(J53))
  let AN63Value = isNaN(AN63) ? '-' : Number(Number(AN63) * 100).toFixed(2)

  let AR63 = Number(Number(T50) * Number(P54))
  let AR63Value = isNaN(AR63) ? '-' : Number(Number(AR63) * 100).toFixed(2)

  let AV63 = Number(Number(T50) * Number(R54))
  let AV63Value = isNaN(AV63) ? '-' : Number(Number(AV63) * 100).toFixed(2)

  let H66 = Number(
    Number(T50) * (Number(Z54) + Number(AB54) + Number(AD54) + Number(AF54))
  )
  let H66Value = isNaN(H66) ? '-' : Number(Number(H66) * 100).toFixed(2)

  let L66 = Number(Number(T50) * Number(L54))
  let L66Value = isNaN(L66) ? '-' : Number(Number(L66) * 100).toFixed(2)

  let P66 = Number(Number(T50) * Number(R50))
  let P66Value = isNaN(P66) ? '-' : Number(Number(P66) * 100).toFixed(2)

  let T66 = Number(Number(V50) * Number(L54))
  let T66Value = isNaN(T66) ? '-' : Number(Number(T66) * 100).toFixed(2)

  let X66 = Number(Number(V50) * Number(H54))
  let X66Value = isNaN(X66) ? '-' : Number(Number(X66) * 100).toFixed(2)

  let AB66 = Number(Number(V50) * Number(J53))
  let AB66Value = isNaN(AB66) ? '-' : Number(Number(AB66) * 100).toFixed(2)

  let AF66 = Number(Number(V50) * Number(T54))
  let AF66Value = isNaN(AF66) ? '-' : Number(Number(AF66) * 100).toFixed(2)

  let AJ66 = Number(Number(V50) * Number(V54))
  let AJ66Value = isNaN(AJ66) ? '-' : Number(Number(AJ66) * 100).toFixed(2)

  let AN66 = Number(Number(X50) * Number(H54))
  let AN66Value = isNaN(AN66) ? '-' : Number(Number(AN66) * 100).toFixed(2)

  let AR66 = Number(Number(X50) * Number(J53))
  let AR66Value = isNaN(AR66) ? '-' : Number(Number(AR66) * 100).toFixed(2)

  let AV66 = Number(Number(X50) * Number(L54))
  let AV66Value = isNaN(AV66) ? '-' : Number(Number(AV66) * 100).toFixed(2)

  let H69 = Number(Number(X50) * Number(N54))
  let H69Value = isNaN(H69) ? '-' : Number(Number(H69) * 100).toFixed(2)

  let L69 = Number(Number(X50) * Number(P54))
  let L69Value = isNaN(L69) ? '-' : Number(Number(L69) * 100).toFixed(2)

  let P69 = Number(Number(X50) * Number(R54))
  let P69Value = isNaN(P69) ? '-' : Number(Number(P69) * 100).toFixed(2)

  let T69 = Number(Number(X50) * Number(V54))
  let T69Value = isNaN(P69) ? '-' : Number(Number(T69) * 100).toFixed(2)

  let X69 = Number(Number(X50) * Number(T54))
  let X69Value = isNaN(X69) ? '-' : Number(Number(X69) * 100).toFixed(2)

  let AB69 = Number(Number(X50) * Number(X54))
  let AB69Value = isNaN(AB69) ? '-' : Number(Number(AB69) * 100).toFixed(2)

  let H72 = Number(H21) * 1.2
  let H72Value = isNaN(H72) ? '-' : Number(H72).toFixed(2)

  let J72 = Number(Number(H72) / 1.7)
  let J72Value = isNaN(J72) ? '-' : Number(J72).toFixed(2)

  let L72 = Number(J72) / 1.9
  let L72Value = isNaN(L72) ? '-' : Number(L72).toFixed(2)

  let N72 = Number(L72) / 1.95
  let N72Value = isNaN(N72) ? '-' : Number(N72).toFixed(2)

  let T72 = Number(100 - N72)
  let T72Value = isNaN(T72) ? '-' : Number(T72).toFixed(2)

  let V72 = Number(100 - H72)
  let V72Value = isNaN(V72) ? '-' : Number(V72).toFixed(2)

  let X72 = Number(Number(H72) / 2.33)
  let X72Value = isNaN(X72) ? '-' : Number(X72).toFixed(2)

  let Z72 = Number(Number(X72) / 1.5)
  let Z72Value = isNaN(Z72) ? '-' : Number(Z72).toFixed(2)

  let P72 = Number(V72) + Number(X72)
  let P72Value = isNaN(P72) ? '-' : Number(P72).toFixed(2)

  let R72 = Number(V72) + Number(X72) + Number(Z72)
  let R72Value = isNaN(R72) ? '-' : Number(R72).toFixed(2)

  let AB72 = Number(
    100 - (Number(N72) + Number(V72) + Number(X72) + Number(Z72))
  )
  let AB72Value = isNaN(AB72) ? '-' : Number(AB72).toFixed(2)

  let AD72 = Number(Number(X72) + Number(Z72))
  let AD72Value = isNaN(AD72) ? '-' : Number(AD72).toFixed(2)

  let AF72 = Number(Number(X72) + Number(Z72) + Number(AB72))
  let AF72Value = isNaN(AF72) ? '-' : Number(AF72).toFixed(2)

  let AH72 = Number(Number(Z72) + Number(AB72))
  let AH72Value = isNaN(AH72) ? '-' : Number(AH72).toFixed(2)

  let AJ72 = Number(Number(J72) - 5)
  let AJ72Value = isNaN(AJ72) ? '-' : Number(AJ72).toFixed(2)

  let AL72 = Number(Number(AJ72) + 4)
  let AL72Value = isNaN(AL72) ? '-' : Number(AL72).toFixed(2)

  let AN72 = Number(Number(AL72) + 1)
  let AN72Value = isNaN(AN72) ? '-' : Number(AN72).toFixed(2)

  let AR72 = Number(Number(AN72) / 2)
  let AR72Value = isNaN(AR72) ? '-' : Number(AR72).toFixed(2)

  let AP72 = Number(Number(AR72) - 3.5)
  let AP72Value = isNaN(AR72) ? '-' : Number(AP72).toFixed(2)

  let AT72 = Number(Number(AP72) - 7)
  let AT72Value = isNaN(AT72) ? '-' : Number(AT72).toFixed(2)

  let AV72 = Number(Number(AB72) / 2)
  let AV72Value = isNaN(AV72) ? '-' : Number(Number(AV72)).toFixed(2)

  let AX72 = Number(Number(AV72) / 2)
  let AX72Value = isNaN(AX72) ? '-' : Number(AX72).toFixed(2)

  let H75 = Number(Number(H72) * 0.7)
  let H75Value = isNaN(H75) ? '-' : Number(H75).toFixed(2)

  let J75 = Number(Number(H75) * 0.275)
  let J75Value = isNaN(J75) ? '-' : Number(J75).toFixed(2)

  let L75 = Number(Number(J75) * 0.2)
  let L75Value = isNaN(L75) ? '-' : Number(Number(L75)).toFixed(2)

  let N75 = () => {
    if (Number(L75) - 3 < 0) {
      let result = 0
      return isNaN(result) ? '-' : result.toFixed(2)
    } else {
      let result = Number(L75) - 3
      return isNaN(result) ? '-' : result.toFixed(2)
    }
  }

  let P75 = Number(100 - Number(H75))
  let P75Value = isNaN(P75) ? '-' : Number(P75).toFixed(2)

  let R75 = Number(100 - Number(J75) - Number(P75))
  let R75Value = isNaN(R75) ? '-' : Number(R75).toFixed(2)

  let T75 = Number(100 - Number(L75) - Number(P75) - Number(R75))
  let T75Value = isNaN(T75) ? '-' : Number(T75).toFixed(2)

  let V75 = Number(Number(P75) + Number(R75))
  let V75Value = isNaN(V75) ? '-' : Number(V75).toFixed(2)

  let X75 = Number(Number(R75) + Number(T75))
  let X75Value = isNaN(X75) ? '-' : Number(X75).toFixed(2)

  let Z75 = Number(Number(J75) - Number(N75()))
  let Z75Value = isNaN(Z75) ? '-' : Number(Z75).toFixed(2)

  let AB75 = Number(H75)
  let AB75Value = isNaN(AB75) ? '-' : Number(AB75).toFixed(2)

  let AD75 = Number(
    100 - Number(N75()) - Number(P75) - Number(R75) - Number(T75)
  )
  let AD75Value = isNaN(AD75) ? '-' : Number(AD75).toFixed(2)

  let H78 = Number(H75 * 1.035)
  let H78Value = isNaN(H78) ? '-' : Number(H78).toFixed(2)

  let J78 = Number(Number(J75) * 1.035)
  let J78Value = isNaN(J78) ? '-' : Number(J78).toFixed(2)

  let L78 = Number(Number(L75) + 4)
  let L78Value = isNaN(L78) ? '-' : Number(L78).toFixed(2)

  let N78 = Number(Number(N75()) + 1)
  let N78Value = isNaN(N78) ? '-' : Number(N78).toFixed(2)

  let P78 = Number(100 - Number(H78))
  let P78Value = isNaN(P78) ? '-' : Number(P78).toFixed(2)

  let R78 = Number(100 - Number(J78) - Number(P78))
  let R78Value = isNaN(R78) ? '-' : Number(R78).toFixed(2)

  let T78 = Number(100 - Number(L78) - Number(P78) - Number(R78))
  let T78Value = isNaN(T78) ? '-' : Number(T78).toFixed(2)

  let V78 = Number(Number(P78) + Number(R78))
  let V78Value = isNaN(V78) ? '-' : Number(V78).toFixed(2)

  let X78 = Number(Number(R78) + Number(T78))
  let X78Value = isNaN(X78) ? '-' : Number(X78).toFixed(2)

  let Z78 = Number(Number(J78) - Number(N78))
  let Z78Value = isNaN(Z78) ? '-' : Number(Z78).toFixed(2)

  let AB78 = Number(H78)
  let AB78value = isNaN(AB78) ? '-' : Number(AB78).toFixed(2)

  let H81 = Number(Number(H75) * Number(J72))
  let H81Value = isNaN(H81) ? '-' : Number(Number(H81) / 100).toFixed(2)

  let L81 = Number(Number(H75) * Number(L72))
  let L81Value = isNaN(L81) ? '-' : Number(Number(L81) / 100).toFixed(2)

  let P81 = Number(Number(H75) * Number(J78))
  let P81Value = isNaN(P81) ? '-' : Number(Number(P81) / 100).toFixed(2)

  let T81 = Number(Number(J75) * Number(H78))
  let T81Value = isNaN(T81) ? '-' : Number(Number(T81) / 100).toFixed(2)

  let X81 = Number(Number(J75) * Number(J78))
  let X81Value = isNaN(X81) ? '-' : Number(Number(X81) / 100).toFixed(2)

  let AB81 = Number(Number(V75) * Number(V78))
  let AB81Value = isNaN(AB81) ? '-' : Number(Number(AB81) / 100).toFixed(2)

  let AF81 = Number(Number(V75) * (Number(V78) + Number(T78)))
  let AF81Value = isNaN(AF81) ? '-' : Number(Number(AF81) / 100).toFixed(2)

  let AJ81 = Number((Number(V75) + Number(T75)) * Number(V78)).toFixed(2)
  let AJ81Value = isNaN(AJ81) ? '-' : Number(Number(AJ81) / 100).toFixed(2)

  let AN81 = Number(
    (Number(V75) + Number(T75)) * (Number(V78) + Number(T78))
  ).toFixed(2)
  let AN81Value = isNaN(AN81) ? '-' : Number(Number(AN81) / 100).toFixed(2)

  let AR81 = Number(Number(R75) * Number(N72))
  let AR81Value = isNaN(AR81) ? '-' : Number(Number(AR81) / 100).toFixed(2)

  let H84 = Number(Number(L18) * 1.2)
  let H84Value = isNaN(H84) ? '-' : Number(H84).toFixed(2)

  let J84 = Number(Number(H84) / 1.7)
  let J84Value = isNaN(J84) ? '-' : Number(J84).toFixed(2)

  let L84 = Number(Number(J84) / 1.9)
  let L84Value = isNaN(L84) ? '-' : Number(L84).toFixed(2)

  let N84 = Number(Number(L84) / 1.95)
  let N84Value = isNaN(N84) ? '-' : Number(N84).toFixed(2)

  let T84 = Number(100 - Number(N84))
  let T84Value = isNaN(T84) ? '-' : Number(T84).toFixed(2)

  let V84 = Number(100 - Number(H84))
  let V84Value = isNaN(V84) ? '-' : Number(V84).toFixed(2)

  let X84 = Number(Number(H84) / 2.33)
  let X84Value = isNaN(X84) ? '-' : Number(X84).toFixed(2)

  let Z84 = Number(Number(X84) / 1.5)
  let Z84Value = isNaN(Z84) ? '-' : Number(Z84).toFixed(2)

  let R84 = Number(Number(V84) + Number(X84) + Number(Z84))
  let R84Value = isNaN(R84) ? '-' : Number(R84).toFixed(2)

  let P84 = Number(Number(V84) + Number(X84))
  let P84Value = isNaN(P84) ? '-' : Number(P84).toFixed(2)

  let AB84 = Number(
    100 - (Number(N84) + Number(V84) + Number(X84) + Number(Z84))
  )
  let AB84Value = isNaN(AB84) ? '-' : Number(AB84).toFixed(2)

  let AD84 = Number(Number(X84) + Number(Z84))
  let AD84Value = isNaN(AD84) ? '-' : Number(AD84).toFixed(2)

  let AF84 = Number(Number(X84) + Number(Z84) + Number(AB84))
  let AF84Value = isNaN(AF84) ? '-' : Number(AF84).toFixed(2)

  let AH84 = Number(Number(Z84) + Number(AB84))
  let AH84Value = isNaN(AH84) ? '-' : Number(AH84).toFixed(2)

  let AJ84 = Number(Number(J84) - 5)
  let AJ84Value = isNaN(AJ84) ? '-' : Number(AJ84).toFixed(2)

  let AL84 = Number(Number(AJ84) + 4)
  let AL84Value = isNaN(AL84) ? '-' : Number(AL84).toFixed(2)

  let AN84 = Number(Number(AL84) + 1)
  let AN84Value = isNaN(AN84) ? '-' : Number(AN84).toFixed(2)

  let AR84 = Number(Number(AN84) / 2)
  let AR84Value = isNaN(AR84) ? '-' : Number(AR84).toFixed(2)

  let AP84 = Number(Number(AR84) - 3.5)
  let AP84Value = isNaN(AP84) ? '-' : Number(AP84).toFixed(2)

  let AT84 = Number(Number(AP84) - 7)
  let AT84Value = isNaN(AT84) ? '-' : Number(AT84).toFixed(2)

  let AV84 = Number(AB84) / 2
  let AV84Value = isNaN(AV84) ? '-' : Number(AV84).toFixed(2)

  let AX84 = Number(Number(AV84) / 2)
  let AX84Value = isNaN(AX84) ? '-' : Number(AX84).toFixed(2)

  let H87 = Number(H84) * 0.7
  let H87Value = isNaN(H87) ? '-' : Number(H87).toFixed(2)

  let J87 = Number(H87) * 0.275
  let J87Value = isNaN(J87) ? '-' : Number(J87).toFixed(2)

  let L87 = Number(J87) * 0.2
  let L87Value = isNaN(L87) ? '-' : Number(L87).toFixed(2)

  let N87 = () => {
    if (L87 - 3 < 0) {
      let result = 0
      return isNaN(result) ? '-' : result.toFixed(2)
    } else {
      let result = Number(L87) - 3
      return isNaN(result) ? '-' : result.toFixed(2)
    }
  }

  let P87 = Number(100 - Number(H87))
  let P87Value = isNaN(P87) ? '-' : Number(P87).toFixed(2)

  let R87 = Number(100 - J87 - P87)
  let R87Value = isNaN(R87) ? '-' : Number(R87).toFixed(2)

  let T87 = Number(100 - Number(L87) - Number(P87) - Number(R87))
  let T87Value = isNaN(T87) ? '-' : Number(T87).toFixed(2)

  let V87 = Number(Number(P87) + Number(R87))
  let V87Value = isNaN(V87) ? '-' : Number(V87).toFixed(2)

  let X87 = Number(Number(R87) + Number(T87))
  let X87Value = isNaN(X87) ? '-' : Number(X87).toFixed(2)

  let Z87 = Number(Number(J87) - Number(N87()))
  let Z87Value = isNaN(Z87) ? '-' : Number(Z87).toFixed(2)

  let AD87 = Number(
    100 + Number(N87()) - Number(P87) - Number(R87) - Number(T87)
  )
  let AD87Value = isNaN(AD87) ? '-' : Number(AD87).toFixed(2)

  let H90 = Number(H87) * 1.035
  let H90Value = isNaN(H90) ? '-' : Number(H90).toFixed(2)

  let J90 = Number(J87) * 1.035
  let J90Value = isNaN(J90) ? '-' : Number(J90).toFixed(2)

  let L90 = Number(L87) + 4
  let L90Value = isNaN(L90) ? '-' : Number(L90).toFixed(2)

  let N90 = Number(N87()) + 1
  let N90Value = isNaN(N90) ? '-' : Number(N90).toFixed(2)

  let AB87 = Number(H90)
  let AB87Value = isNaN(AB87) ? '-' : Number(AB87).toFixed(2)

  let P90 = Number(100 - Number(H90))
  let P90Value = isNaN(P90) ? '-' : Number(P90).toFixed(2)

  let R90 = Number(100 - Number(J90) - Number(P90))
  let R90Value = isNaN(R90) ? '-' : Number(R90).toFixed(2)

  let T90 = Number(100 - Number(L90) - Number(P90) - Number(R90))
  let T90Value = isNaN(T90) ? '-' : Number(T90).toFixed(2)

  let V90 = Number(Number(P90) + Number(R90))
  let V90Value = isNaN(V90) ? '-' : Number(V90).toFixed(2)

  let X90 = Number(R90) + Number(T90)
  let X90Value = isNaN(X90) ? '-' : Number(X90).toFixed(2)

  let Z90 = Number(Number(J90) - Number(N90))
  let Z90Value = isNaN(Z90) ? '-' : Number(Z90).toFixed(2)

  let AB90 = Number(H90)
  let AB90Value = isNaN(AB90) ? '-' : Number(AB90).toFixed(2)

  let H93 = Number((Number(H87) * Number(J84)) / 100)
  let H93Value = isNaN(H93) ? '-' : Number(H93).toFixed(2)

  let L93 = Number((Number(H87) * Number(L84)) / 100)
  let L94Value = isNaN(L93) ? '-' : Number(L93).toFixed(2)

  let P93 = Number((Number(H87) * Number(J90)) / 100)
  let P93Value = isNaN(P93) ? '-' : Number(P93).toFixed(2)

  let T93 = Number((Number(J87) * Number(H90)) / 100)
  let T93Value = isNaN(T93) ? '-' : Number(T93).toFixed(2)

  let X93 = Number((Number(J87) * Number(J90)) / 100)
  let X93Value = isNaN(X93) ? '-' : Number(X93).toFixed(2)

  let AB93 = Number((Number(V87) * Number(V90)) / 100)
  let AB93Value = isNaN(AB93) ? '-' : Number(AB93).toFixed(2)

  let AF93 = Number((Number(V87) * Number(V90 + T90)) / 100)
  let AF93Value = isNaN(AF93) ? '-' : Number(AF93).toFixed(2)

  let AJ93 = Number(((Number(V87) + Number(T87)) * Number(V90)) / 100)
  let AJ93Value = isNaN(AJ93) ? '-' : Number(AJ93).toFixed(2)

  let AN93 =
    Number(Number(V87) + Number(T87) + (Number(V90) + Number(T90))) - 100
  let AN93Value = isNaN(AN93) ? '-' : Number(AN93).toFixed(2)

  let AR93 = Number((Number(R87) * Number(N84)) / 100)
  let AR93Value = isNaN(AR93) ? '-' : Number(AR93).toFixed(2)

  let H96 = Number(
    Number(V10) * (Number(N10) + Number(P10) + Number(R10) + Number(T10))
  )
  let H96Value = isNaN(H96) ? '-' : Number(Number(H96) * 100).toFixed(2)

  let J96 = Number(Number(V10) * (Number(P10) + Number(R10) + Number(T10)))
  let J96Value = isNaN(J96) ? '-' : Number(Number(J96) * 100).toFixed(2)

  let L96 = Number(Number(J72) * Number(J84))
  let L96Value = isNaN(L96) ? '-' : Number(Number(L96) / 100).toFixed(2)

  let N96 = Number(Number(V10) * Number(J72))
  let N96Value = isNaN(N96) ? '-' : Number(N96).toFixed(2)

  let P96 = Number(Number(V10) * Number(J84))
  let P96Value = isNaN(P96) ? '-' : Number(P96).toFixed(2)

  let R96 = Number(Number(V10) * Number(L35))
  let R96Value = isNaN(R96) ? '-' : Number(Number(R96) * 100).toFixed(2)

  let T96 = Number(
    Number(X10) * (Number(N10) + Number(P10) + Number(R10) + Number(T10))
  )
  let T96Value = isNaN(T96) ? '-' : Number(Number(T96) * 100).toFixed(2)

  let J99 = Number(V72)
  let J99Value = isNaN(J99) ? '-' : Number(J99).toFixed(2)

  let H99 = Number(100 - Number(J99))
  let H99Value = isNaN(H99) ? '-' : Number(H99).toFixed(2)

  let J102 = Number(V84)
  let J102Value = isNaN(J102) ? '-' : Number(J102).toFixed(2)

  let H102 = Number(100 - Number(J102))
  let H102Value = isNaN(H102) ? '-' : Number(H102).toFixed(2)

  let H105 = 0.3
  let H105Value = Number(Number(H105) * 100).toFixed(2)

  let J105 = 0.2
  let J105Value = Number(Number(J105) * 100).toFixed(2)

  let L105 = 0.5
  let L105Value = Number(Number(L105) * 100).toFixed(2)

  let H110 = Number(Number(H18) * Number(Z18()))
  let H110Value = isNaN(H110) ? '-' : Number(Number(H110) / 100).toFixed(2)

  let J110 = () => {
    if (Z18() * J18 < Z18() * L18) {
      let result = Z18() * L18
      return isNaN(result) ? '-' : Number(result / 100).toFixed(2)
    } else {
      let result = Z18() * J18
      return isNaN(result) ? '-' : Number(result / 100).toFixed(2)
    }
  }

  let L110 = () => {
    if (Z18() * J18 < Z18() * L18) {
      let result = Z18() * J18
      return isNaN(result) ? '-' : Number(result / 100).toFixed(2)
    } else {
      let result = Z18() * L18
      return isNaN(result) ? '-' : Number(result / 100).toFixed(2)
    }
  }

  let N110 = Number(Number(AB18) * Number(H18))
  let N110Value = isNaN(N110) ? '-' : Number(N110 / 100).toFixed(2)

  let P110 = Number(Number(J18) * Number(AB18))
  let P110Value = isNaN(P110) ? '-' : Number(P110 / 100).toFixed(2)

  let R110 = Number(Number(AB18) * Number(L18))
  let R110Value = isNaN(R110) ? '-' : Number(R110 / 100).toFixed(2)

  let T110 = () => {
    if (AD18() * H18 < AD18() * J18) {
      let result = AD18() * H18
      return isNaN(result) ? '-' : Number(result / 100).toFixed(2)
    } else {
      let result = AD18() * J18
      return isNaN(result) ? '-' : Number(result / 100).toFixed(2)
    }
  }

  let V110 = () => {
    if (AD18() * H18 > AD18() * J18) {
      let result = Number(AD18() * H18)
      return isNaN(result) ? '-' : Number(result / 100).toFixed(2)
    } else {
      let result = Number(AD18() * J18)
      return isNaN(result) ? '-' : Number(result / 100).toFixed(2)
    }
  }

  let X110 =
    100 -
    H110Value -
    J110() -
    L110() -
    N110Value -
    P110Value -
    R110Value -
    T110() -
    V110()
  let X110Value = isNaN(X110) ? '-' : Number(X110).toFixed(2)

  let Z110 = Number(
    Number(J110()) +
      Number(L110()) +
      Number(N110Value) +
      Number(P110Value) +
      Number(R110Value) +
      Number(T110()) +
      Number(V110()) +
      Number(X110Value)
  )
  let Z110Value = isNaN(Z110) ? '-' : Number(Z110).toFixed(2)

  let AB110 = Number(
    Number(H110Value) +
      Number(J110()) +
      Number(L110()) +
      Number(N110Value) +
      Number(P110Value) +
      Number(R110Value) +
      Number(T110()) +
      Number(V110())
  )
  let AB110Value = isNaN(AB110) ? '-' : Number(AB110).toFixed(2)

  let AD110 = Number(
    Number(H110Value) +
      Number(J110()) +
      Number(L110()) +
      Number(P110Value) +
      Number(R110Value) +
      Number(T110()) +
      Number(V110()) +
      Number(X110Value)
  )
  let AD110Value = isNaN(AD110) ? '-' : Number(AD110).toFixed(2)

  let AF110 = Number(
    Number(H110Value) +
      Number(J110()) +
      Number(L110()) +
      Number(N110Value) +
      Number(R110Value) +
      Number(T110()) +
      Number(V110()) +
      Number(X110Value)
  )
  let AF110Value = isNaN(AF110) ? '-' : Number(AF110).toFixed(2)

  let AH110 = Number(
    Number(H110Value) +
      Number(J110()) +
      Number(L110()) +
      Number(N110Value) +
      Number(P110Value) +
      Number(T110()) +
      Number(V110()) +
      Number(X110Value)
  )
  let AH110Value = isNaN(AH110) ? '-' : Number(AH110).toFixed(2)

  let AJ110 = Number(
    Number(H110Value) +
      Number(J110()) +
      Number(N110Value) +
      Number(P110Value) +
      Number(R110Value) +
      Number(T110()) +
      Number(V110()) +
      Number(X110Value)
  )
  let AJ110Value = isNaN(AJ110) ? '-' : Number(AJ110).toFixed(2)

  let AL110 = Number(
    Number(H110Value) +
      Number(L110()) +
      Number(N110Value) +
      Number(P110Value) +
      Number(R110Value) +
      Number(T110()) +
      Number(V110()) +
      Number(X110Value)
  )
  let AL110Value = isNaN(AL110) ? '-' : Number(AL110).toFixed(2)

  let AN110 = Number(
    Number(H110Value) +
      Number(J110()) +
      Number(L110()) +
      Number(N110Value) +
      Number(P110Value) +
      Number(R110Value) +
      Number(V110()) +
      Number(X110Value)
  )
  let AN110Value = isNaN(AN110) ? '-' : Number(AN110).toFixed(2)

  let AP110 = Number(
    Number(H110Value) +
      Number(J110()) +
      Number(L110()) +
      Number(N110Value) +
      Number(P110Value) +
      Number(R110Value) +
      Number(T110()) +
      Number(X110Value)
  )
  let AP110Value = isNaN(AP110) ? '-' : Number(AP110).toFixed(2)

  let H113 = Number(Number(H110Value) + Number(J110()))
  let H113Value = isNaN(H113) ? '-' : Number(H113).toFixed(2)

  let J113 = Number(Number(J110()) + Number(L110()))
  let J113Value = isNaN(J113) ? '-' : Number(J113).toFixed(2)

  let L113 = Number(Number(H110Value) + Number(L110()))
  let L113Value = isNaN(L113) ? '-' : Number(L113).toFixed(2)

  let N113 = Number(Number(T110()) + Number(V110()))
  let N113Value = isNaN(N113) ? '-' : Number(N113).toFixed(2)

  let P113 = Number(Number(V110()) + Number(X110Value))
  let P113Value = isNaN(P113) ? '-' : Number(P113).toFixed(2)

  let T113 = Number(Number(N110Value) + Number(P110Value))
  let T113Value = isNaN(T113) ? '-' : Number(T113).toFixed(2)

  let V113 = Number(Number(P110Value) + Number(R110Value))
  let V113Value = isNaN(V113) ? '-' : Number(V113).toFixed(2)

  let X113 = Number(Number(N110Value) + Number(R110Value))
  let X113Value = isNaN(X113) ? '-' : Number(X113).toFixed(2)

  let Z113 = Number(Number(H110Value) + Number(N110Value))
  let Z113Value = isNaN(Z113) ? '-' : Number(Z113).toFixed(2)

  let AB113 = Number(Number(J110()) + Number(P110Value))
  let AB113Value = isNaN(AB113) ? '-' : Number(AB113).toFixed(2)

  let AD113 = Number(Number(L110()) + Number(R110Value))
  let AD113Value = isNaN(AD113) ? '-' : Number(AD113).toFixed(2)

  let AF113 = Number(Number(P110Value) + Number(V110()))
  let AF113Value = isNaN(AF113) ? '-' : Number(AF113).toFixed(2)

  let AH113 = Number(Number(R110Value) + Number(P110Value))
  let AH113Value = isNaN(AH113) ? '-' : Number(AH113).toFixed(2)

  let AJ113 = Number(Number(R110Value) + Number(X110Value))
  let AJ113Value = isNaN(AJ113) ? '-' : Number(AJ113).toFixed(2)

  let AL113 = Number(Number(H110Value) + Number(T110()))
  let AL113Value = isNaN(AL113) ? '-' : Number(AL113).toFixed(2)

  let AN113 = Number(Number(J110()) + Number(V110()))
  let AN113Value = isNaN(AN113) ? '-' : Number(AN113).toFixed(2)

  let AP113 = Number(Number(L110()) + Number(X110Value))
  let AP113Value = isNaN(AP113) ? '-' : Number(AP113).toFixed(2)

  let AR113 = Number(
    Number(H110Value) + Number(J110()) + Number(N110Value) + Number(P110Value)
  )
  let AR113Value = isNaN(AR113) ? '-' : Number(AR113).toFixed(2)

  let AT113 = Number(
    Number(J110()) + Number(L110()) + Number(P110Value) + Number(R110Value)
  )
  let AT113Value = isNaN(AT113) ? '-' : Number(AT113).toFixed(2)

  let AV113 = Number(
    Number(H110Value) + Number(L110()) + Number(N110Value) + Number(R110Value)
  )
  let AV113Value = isNaN(AV113) ? '-' : Number(AV113).toFixed(2)

  let AX113 = Number(
    Number(N110Value) + Number(P110Value) + Number(T110()) + Number(V110())
  )
  let AX113Value = isNaN(AX113) ? '-' : Number(AX113).toFixed(2)

  let H116 = Number(
    Number(P110Value) + Number(R110Value) + Number(V110()) + Number(X110Value)
  )
  let H116Value = isNaN(H116) ? '-' : Number(H116).toFixed(2)

  let J116 = Number(
    Number(R110Value) + Number(N110Value) + Number(T110()) + Number(X110Value)
  )
  let J116Value = isNaN(J116) ? '-' : Number(J116).toFixed(2)

  let L116 = Number(
    Number(H110Value) + Number(J110()) + Number(T110()) + Number(V110())
  )
  let L116Value = isNaN(L116) ? '-' : Number(L116).toFixed(2)

  let N116 = Number(
    Number(J110()) + Number(L110()) + Number(V110()) + Number(X110Value)
  )
  let N116Value = isNaN(N116) ? '-' : Number(N116).toFixed(2)

  let P116 = Number(
    Number(H110Value) + Number(L110()) + Number(T110()) + Number(X110Value)
  )
  let P116Value = isNaN(P116) ? '-' : Number(P116).toFixed(2)

  let R113 = Number(Number(T110()) + Number(X110Value))
  let R113Value = isNaN(R113) ? '-' : Number(R113).toFixed(2)

  let H129 = Number(Number(B10) * Number(J54))
  let H129Value = isNaN(H129) ? '-' : Number(H129).toFixed(2)

  let J129 = Number(Number(B10) * Number(L72))
  let J129Value = isNaN(J129) ? '-' : Number(J129).toFixed(2)

  let L129 = Number(Number(B10) * Number(AH6))
  let L129Value = isNaN(L129) ? '-' : Number(L129 * 100).toFixed(2)

  let N129 = Number(Number(B10) * Number(AV35))
  let N129Value = isNaN(N129) ? '-' : Number(N129 * 100).toFixed(2)

  let P129 = Number(Number(B10) * Number(AX35))
  let P129Value = isNaN(P129) ? '-' : Number(P129 * 100).toFixed(2)

  let R129 = Number(Number(B10) * Number(H50))
  let R129Value = isNaN(R129) ? '-' : Number(R129 * 100).toFixed(2)

  let T129 = Number(Number(B10) * Number(J50))
  let T129Value = isNaN(T129) ? '-' : Number(T129 * 100).toFixed(2)

  let V129 = Number(Number(B10) * Number(H54))
  let V129Value = isNaN(V129) ? '-' : Number(V129 * 100).toFixed(2)

  let X129 = Number(Number(B10) * Number(J53))
  let X129Value = isNaN(X129) ? '-' : Number(X129 * 100).toFixed(2)

  let Z129 = Number(Number(B10) * Number(H50) * Number(H54))
  let Z129Value = isNaN(Z129) ? '-' : Number(Z129 * 100).toFixed(2)

  let AD129 = Number(Number(B10) * Number(AF6))
  let AD129Value = isNaN(AD129) ? '-' : Number(AD129 * 100).toFixed(2)

  let AF129 = Number(Number(B10) * Number(AD35))
  let AF129Value = isNaN(AF129) ? '-' : Number(AF129 * 100).toFixed(2)

  let AH129 = Number(Number(B10) * Number(AL35))
  let AH129Value = isNaN(AH129) ? '-' : Number(AH129 * 100).toFixed(2)

  let AJ129 = Number(Number(B10) * Number(L35))
  let AJ129Value = isNaN(AJ129) ? '-' : Number(AJ129 * 100).toFixed(2)

  let AL129 = Number(Number(B10) * Number(X35))
  let AL129Value = isNaN(AL129) ? '-' : Number(AL129 * 100).toFixed(2)

  let AN129 = Number(Number(B10) * Number(AH35))
  let AN129Value = isNaN(AN129) ? '-' : Number(AN129 * 100).toFixed(2)

  let AP129 = Number(Number(B10) * Number(AP35))
  let AP129Value = isNaN(AP129) ? '-' : Number(AP129 * 100).toFixed(2)

  let AR129 = Number(Number(B10) * Number(N35))
  let AR129Value = isNaN(AR129) ? '-' : Number(AR129 * 100).toFixed(2)

  let AT129 = Number(Number(B10) * Number(Z35))
  let AT129Value = isNaN(AT129) ? '-' : Number(AT129 * 100).toFixed(2)

  let AV129 = Number(Number(B10) * Number(AJ35))
  let AV129Value = isNaN(AV129) ? '-' : Number(AV129 * 100).toFixed(2)

  let AX129 = Number(Number(B10) * Number(X50))
  let AX129Value = isNaN(AX129) ? '-' : Number(AX129 * 100).toFixed(2)

  let H132 = Number(Number(F10) * Number(J84))
  let H132Value = isNaN(H132) ? '-' : Number(H132).toFixed(2)

  let J132 = Number(Number(F10) * Number(L84))
  let J132Value = isNaN(J132) ? '-' : Number(J132).toFixed(2)

  let L132 = Number(Number(F10) * Number(AH6))
  let L132Value = isNaN(L132) ? '-' : Number(L132 * 100).toFixed(2)

  let N132 = Number(Number(F10) * Number(AV35))
  let N132Value = isNaN(N132) ? '-' : Number(N132 * 100).toFixed(2)

  let P132 = Number(Number(F10) * Number(AX35))
  let P132Value = isNaN(P132) ? '-' : Number(P132 * 100).toFixed(2)

  let R132 = Number(Number(F10) * Number(H50))
  let R132Value = isNaN(R132) ? '-' : Number(R132 * 100).toFixed(2)

  let T132 = Number(Number(F10) * Number(J50))
  let T132Value = isNaN(T132) ? '-' : Number(T132 * 100).toFixed(2)

  let V132 = Number(Number(B10) * Number(J54))
  let V132Value = isNaN(V132) ? '-' : Number(V132 * 100).toFixed(2)

  let X132 = Number(Number(F10) * Number(J54))
  let X132Value = isNaN(X132) ? '-' : Number(X132 * 100).toFixed(2)

  let Z132 = Number(Number(F10) * Number(H50) * Number(H54))
  let Z132Value = isNaN(Z132) ? '-' : Number(Z132 * 100).toFixed(2)

  let AD132 = Number(Number(F10) * Number(AF6))
  let AD132Value = isNaN(AD132) ? '-' : Number(AD132 * 100).toFixed(2)

  let AF132 = Number(Number(F10) * Number(AD35))
  let AF132Value = isNaN(AF132) ? '-' : Number(AF132 * 100).toFixed(2)

  let AH132 = Number(Number(F10) * Number(AL35))
  let AH132Value = isNaN(AH132) ? '-' : Number(AH132 * 100).toFixed(2)

  let AJ132 = Number(Number(F10) * Number(L35))
  let AJ132Value = isNaN(AJ132) ? '-' : Number(AJ132 * 100).toFixed(2)

  let AL132 = Number(Number(F10) * Number(X35))
  let AL132Value = isNaN(AL132) ? '-' : Number(AL132 * 100).toFixed(2)

  let AN132 = Number(Number(F10) * Number(AH35))
  let AN132Value = isNaN(AN132) ? '-' : Number(AN132 * 100).toFixed(2)

  let AP132 = Number(Number(F10) * Number(AP35))
  let AP132Value = isNaN(AP132) ? '-' : Number(AP132 * 100).toFixed(2)

  let AR132 = Number(Number(F10) * Number(N35))
  let AR132Value = isNaN(AR132) ? '-' : Number(AR132 * 100).toFixed(2)

  let AT132 = Number(Number(F10) * Number(Z35))
  let AT132Value = isNaN(AT132) ? '-' : Number(AT132 * 100).toFixed(2)

  let AV132 = Number(Number(F10) * Number(AJ35))
  let AV132Value = isNaN(AV132) ? '-' : Number(AV132 * 100).toFixed(2)

  let AX132 = Number(Number(F10) * Number(X54))
  let AX132Value = isNaN(AX132) ? '-' : Number(AX132 * 100).toFixed(2)

  let H135 = Number(Number(D10) * Number(X50))
  let H135Value = isNaN(H135) ? '-' : Number(H135 * 100).toFixed(2)

  let J135 = Number(Number(D10) * Number(AR35))
  let J135Value = isNaN(J135) ? '-' : Number(J135 * 100).toFixed(2)

  let L135 = Number(Number(D10) * Number(AR35))
  let L135Value = isNaN(L135) ? '-' : Number(L135 * 100).toFixed(2)

  let N135 = Number(Number(D10) * Number(AH6))
  let N135Value = isNaN(N135) ? '-' : Number(N135 * 100).toFixed(2)

  let P135 = Number(Number(D10) * Number(AF6))
  let P135Value = isNaN(P135) ? '-' : Number(P135 * 100).toFixed(2)

  let R135 = Number(Number(B10) * Number(H105))
  let R135Value = isNaN(R135) ? '-' : Number(R135 * 100).toFixed(2)

  let T135 = Number(Number(B10) * Number(J105))
  let T135Value = isNaN(T135) ? '-' : Number(T135 * 100).toFixed(2)

  let V135 = Number(Number(B10) * Number(L105))
  let V135Value = isNaN(V135) ? '-' : Number(V135 * 100).toFixed(2)

  let X135 = Number(Number(F10) * Number(H105))
  let X135Value = isNaN(X135) ? '-' : Number(X135 * 100).toFixed(2)

  let Z135 = Number(Number(F10) * Number(J105))
  let Z135Value = isNaN(Z135) ? '-' : Number(Z135 * 100).toFixed(2)

  let AB135 = Number(Number(F10) * Number(L105))
  let AB135Value = isNaN(AB135) ? '-' : Number(AB135 * 100).toFixed(2)

  let H138 = Number(Number(H18) * Number(V10))
  let H138Value = isNaN(H138) ? '-' : Number(H138).toFixed(2)

  let J138 = Number(Number(D10) * Number(V10))
  let J138Value = isNaN(J138) ? '-' : Number(J138 * 100).toFixed(2)

  let L138 = Number(Number(F10) * Number(V10))
  let L138Value = isNaN(L138) ? '-' : Number(L138 * 100).toFixed(2)

  let H141 = Number(Number(H21) * Number(AR35))
  let H141Value = isNaN(H141) ? '-' : Number(H141).toFixed(2)

  let J141 = Number(Number(H21) * Number(AH6))
  let J141Value = isNaN(J141) ? '-' : Number(J141).toFixed(2)

  let L141 = Number(Number(H21) * Number(AV35))
  let L141Value = isNaN(L141) ? '-' : Number(L141).toFixed(2)

  let N141 = Number(Number(H21) * Number(AF6))
  let N141Value = isNaN(N141) ? '-' : Number(N141).toFixed(2)

  let P141 = Number(Number(H21) * Number(AD35))
  let P141Value = isNaN(P141) ? '-' : Number(P141).toFixed(2)

  let R141 = Number(Number(H21) * Number(L35))
  let R141Value = isNaN(R141) ? '-' : Number(R141).toFixed(2)

  let T141 = Number(Number(H21) * Number(P50))
  let T141Value = isNaN(T141) ? '-' : Number(T141).toFixed(2)

  let V141 = Number(Number(H21) * Number(J50))
  let V141Value = isNaN(V141) ? '-' : Number(V141).toFixed(2)

  let X141 = Number(Number(H21) * Number(J72))
  let X141Value = isNaN(X141) ? '-' : Number(X141 / 100).toFixed(2)

  let Z141 = Number(Number(H21) * Number(J35))
  let Z141Value = isNaN(Z141) ? '-' : Number(Z141).toFixed(2)

  let AB141 = Number(Number(H21) * Number(V35))
  let AB141Value = isNaN(AB141) ? '-' : Number(AB141).toFixed(2)

  let AD141 = Number(Number(H21) * Number(X35))
  let AD141Value = isNaN(AD141) ? '-' : Number(AD141).toFixed(2)

  let AF141 = Number(Number(H21) * Number(AH35))
  let AF141Value = isNaN(AF141) ? '-' : Number(AF141).toFixed(2)

  let AH141 = Number(Number(H21) * Number(Z35))
  let AH141Value = isNaN(AH141) ? '-' : Number(AH141).toFixed(2)

  let AJ141 = Number(Number(H21) * Number(AJ35))
  let AJ141Value = isNaN(AJ141) ? '-' : Number(AJ141).toFixed(2)

  let AL141 = Number(Number(H21) * Number(AB35))
  let AL141Value = isNaN(AL141) ? '-' : Number(AL141).toFixed(2)

  let AN141 = Number(Number(H21) * Number(AB35))
  let AN141Value = isNaN(AN141) ? '-' : Number(AN141).toFixed(2)

  let AP141 = Number(Number(L21) * Number(AR35))
  let AP141Value = isNaN(AP141) ? '-' : Number(AP141).toFixed(2)

  let AR141 = Number(Number(L21) * Number(AV35))
  let AR141Value = isNaN(AR141) ? '-' : Number(AR141).toFixed(2)

  let AT141 = Number(Number(L21) * Number(AF6))
  let AT141Value = isNaN(AT141) ? '-' : Number(AT141).toFixed(2)

  let AV141 = Number(Number(L21) * Number(AD35))
  let AV141Value = isNaN(AV141) ? '-' : Number(AV141).toFixed(2)

  let AX141 = Number(Number(L21) * Number(L35))
  let AX141Value = isNaN(AX141) ? '-' : Number(AX141).toFixed(2)

  let H144 = Number(Number(L21) * Number(AD35))
  let H144Value = isNaN(H144) ? '-' : Number(H144).toFixed(2)

  let J144 = Number(Number(L21) * Number(L35))
  let J144Value = isNaN(J144) ? '-' : Number(J144).toFixed(2)

  let L144 = Number(Number(L21) * Number(P50))
  let L144Value = isNaN(L144) ? '-' : Number(L144).toFixed(2)

  let P144 = Number(L21)
  let P144Value = isNaN(P144) ? '-' : Number(P144).toFixed(2)

  let R144 = Number(Number(L21) * Number(J84))
  let R144Value = isNaN(R144) ? '-' : Number(R144 / 100).toFixed(2)

  let T144 = Number(Number(L21) * Number(J35))
  let T144Value = isNaN(T144) ? '-' : Number(T144).toFixed(2)

  let V144 = Number(Number(L21) * Number(V35))
  let V144Value = isNaN(V144) ? '-' : Number(V144).toFixed(2)

  let X144 = Number(Number(L21) * Number(X35))
  let X144Value = isNaN(X144) ? '-' : Number(X144).toFixed(2)

  let Z144 = Number(Number(L21) * Number(V35))
  let Z144Value = isNaN(Z144) ? '-' : Number(Z144).toFixed(2)

  let AB144 = Number(Number(L21) * Number(X35))
  let AB144Value = isNaN(AB144) ? '-' : Number(AB144).toFixed(2)

  let AD144 = Number(Number(L21) * Number(AH35))
  let AD144Value = isNaN(AD144) ? '-' : Number(AD144).toFixed(2)

  let AF144 = Number(Number(L21) * Number(Z35))
  let AF144Value = isNaN(AF144) ? '-' : Number(AF144).toFixed(2)

  let AH144 = Number(Number(L21) * Number(AJ35))
  let AH144Value = isNaN(AH144) ? '-' : Number(AH144).toFixed(2)

  let AJ144 = Number(Number(L21) * Number(AB35))
  let AJ144Value = isNaN(AJ144) ? '-' : Number(AJ144).toFixed(2)

  let AL144 = Number(Number(J21) * Number(AR35))
  let AL144Value = isNaN(AL144) ? '-' : Number(AL144).toFixed(2)

  let AN144 = Number(Number(J21) * Number(AH6))
  let AN144Value = isNaN(AN144) ? '-' : Number(AN144).toFixed(2)

  let AP144 = Number(Number(J21) * Number(AF6))
  let AP144Value = isNaN(AP144) ? '-' : Number(AP144).toFixed(2)

  let AR144 = Number(Number(J21) * Number(AD35))
  let AR144Value = isNaN(AR144) ? '-' : Number(AR144).toFixed(2)

  let AT144 = Number(Number(J21) * Number(L35))
  let AT144Value = isNaN(AT144) ? '-' : Number(AT144).toFixed(2)

  let AV144 = Number(Number(H21) * Number(V10))
  let AV144Value = isNaN(AV144) ? '-' : Number(AV144).toFixed(2)

  let AX144 = Number(Number(J21) * Number(V10))
  let AX144Value = isNaN(AX144) ? '-' : Number(AX144).toFixed(2)

  let H147 = Number(Number(L21) * Number(V10))
  let H147Value = isNaN(H147) ? '-' : Number(H147).toFixed(2)

  let J147 = Number(Number(H21) * Number(H105))
  let J147Value = isNaN(J147) ? '-' : Number(J147).toFixed(2)

  let L147 = Number(Number(H21) * Number(J105))
  let L147Value = isNaN(L147) ? '-' : Number(L147).toFixed(2)

  let N147 = Number(Number(H21) * Number(L105))
  let N147Value = isNaN(N147) ? '-' : Number(N147).toFixed(2)

  let P147 = Number(Number(L21) * Number(H105))
  let P147Value = isNaN(P147) ? '-' : Number(P147).toFixed(2)

  let R147 = Number(Number(L21) * Number(J105))
  let R147Value = isNaN(R147) ? '-' : Number(R147).toFixed(2)

  let T147 = Number(Number(L21) * Number(L105))
  let T147Value = isNaN(T147) ? '-' : Number(T147).toFixed(2)

  let V147 = Number(Number(L21) * Number(H105))
  let V147Value = isNaN(V147) ? '-' : Number(V147).toFixed(2)

  let X147 = Number(Number(L21) * Number(J105))
  let X147Value = isNaN(X147) ? '-' : Number(X147).toFixed(2)

  let Z147 = Number(Number(L21) * Number(L105))
  let Z147Value = isNaN(Z147) ? '-' : Number(Z147).toFixed(2)

  let AB147 = Number(Number(J21) * Number(H105))
  let AB147Value = isNaN(AB147) ? '-' : Number(AB147).toFixed(2)

  let AD147 = Number(Number(J21) * Number(J105))
  let AD147Value = isNaN(AD147) ? '-' : Number(AD147).toFixed(2)

  let AF147 = Number(Number(J21) * Number(L105))
  let AF147Value = isNaN(AF147) ? '-' : Number(AF147).toFixed(2)

  let H150 = Number((Number(B10) + Number(AH6)) / 2)
  let H150Value = isNaN(H150) ? '-' : Number(H150 * 100).toFixed(2)

  let J150 = Number((Number(B10) + Number(AV35)) / 2)
  let J150Value = isNaN(J150) ? '-' : Number(J150 * 100).toFixed(2)

  let L150 = Number((Number(B10) + Number(V10)) / 2)
  let L150Value = isNaN(L150) ? '-' : Number(L150 * 100).toFixed(2)

  let N150 = Number((Number(F10) + Number(AH6)) / 2)
  let N150Value = isNaN(N150) ? '-' : Number(N150 * 100).toFixed(2)

  let P150 = Number((Number(F10) + Number(AV35)) / 2)
  let P150Value = isNaN(P150) ? '-' : Number(P150 * 100).toFixed(2)

  let R150 = Number((Number(F10) + Number(V10)) / 2)
  let R150Value = isNaN(R150) ? '-' : Number(R150 * 100).toFixed(2)

  let T150 = Number((Number(N72) + Number(N84)) / 2)
  let T150Value = isNaN(T150) ? '-' : Number(T150).toFixed(2)

  let V150 = Number((Number(B10) + Number(J50)) / 2)
  let V150Value = isNaN(V150) ? '-' : Number(V150 * 100).toFixed(2)

  let X150 = Number((Number(F10) + Number(J54)) / 2)
  let X150Value = isNaN(X150) ? '-' : Number(X150 * 100).toFixed(2)

  let Z150 = Number((Number(H110) + Number(N110)) / 2)
  let Z150Value = isNaN(Z150) ? '-' : Number(Z150 / 100).toFixed(2)

  let AB150 = Number((Number(H110) + Number(P110)) / 2)
  let AB150Value = isNaN(AB150) ? '-' : Number(AB150 / 100).toFixed(2)

  let AD150 = Number((Number(H110 / 100) + Number(X110)) / 2)
  let AD150Value = isNaN(AD150) ? '-' : Number(AD150).toFixed(2)

  let AF150 = Number((Number(J50) + Number(J54)) / 2)
  let AF150Value = isNaN(AF150) ? '-' : Number(AF150 * 100).toFixed(2)

  let AJ150 = Number((Number(L41 * 100) + Number(R41)) / 2)
  let AJ150Value = isNaN(AJ150) ? '-' : Number(AJ150).toFixed(2)

  let AL150 = Number((Number(N41) * 100 + Number(P41)) / 2)
  let AL150Value = isNaN(AL150) ? '-' : Number(AL150).toFixed(2)

  let AN150 = Number((Number(L41) * 100 + Number(P41)) / 2)
  let AN150Value = isNaN(AN150) ? '-' : Number(AN150).toFixed(2)

  let H153 = Number(Number(Z18()) * Number(L41))
  let H153Value = isNaN(H153) ? '-' : Number(H153).toFixed(2)

  let J153 = Number(Number(AB18) * Number(L41))
  let J153Value = isNaN(J153) ? '-' : Number(J153).toFixed(2)

  let L153 = Number(Number(AD18()) * Number(L41))
  let L153Value = isNaN(L153) ? '-' : Number(L153).toFixed(2)

  let N153 = Number(Number(Z18()) * Number(N41))
  let N153Value = isNaN(N153) ? '-' : Number(N153).toFixed(2)

  let P153 = Number(Number(Z18()) * Number(N41))
  let P153Value = isNaN(P153) ? '-' : Number(P153).toFixed(2)

  let R153 = Number(Number(AD18()) * Number(N41))
  let R153Value = isNaN(R153) ? '-' : Number(R153).toFixed(2)

  let T153 = Number(Number(AR18()) * Number(P41))
  let T153Value = isNaN(T153) ? '-' : Number(T153 / 100).toFixed(2)

  let X153 = Number(Number(AT18) * Number(P41))
  let X153Value = isNaN(X153) ? '-' : Number(X153 / 100).toFixed(2)

  let Z153 = Number(Number(AV18()) * Number(P41))
  let Z153Value = isNaN(Z153) ? '-' : Number(Z153 / 100).toFixed(2)

  let AB153 = Number(Number(AR18()) * Number(R41))
  let AB153Value = isNaN(AB153) ? '-' : Number(AB153 / 100).toFixed(2)

  let AD153 = Number(Number(AT18) * Number(R41))
  let AD153Value = isNaN(AD153) ? '-' : Number(AD153 / 100).toFixed(2)

  let AF153 = Number(Number(AV18()) * Number(R41))
  let AF153Value = isNaN(AF153) ? '-' : Number(AF153 / 100).toFixed(2)

  let AH153 = Number(Number(L41) * Number(R41))
  let AH153Value = isNaN(AH153) ? '-' : Number(AH153).toFixed(2)

  let AJ153 = Number(Number(N41) * Number(R41))
  let AJ153Value = isNaN(AJ153) ? '-' : Number(AJ153).toFixed(2)

  let AN153 = Number(Number(L41) * Number(P41))
  let AN153Value = isNaN(AN153) ? '-' : Number(AN153).toFixed(2)

  let AP153 = Number(Number(H41) * Number(L50))
  let AP153Value = isNaN(AP153) ? '-' : Number(AP153 * 100).toFixed(2)

  let AR153 = Number(Number(H41) * Number(L54))
  let AR153Value = isNaN(AR153) ? '-' : Number(AR153 * 100).toFixed(2)

  let AT153 = Number(Number(P41) * Number(L53))
  let AT153Value = isNaN(AT153) ? '-' : Number(AT153).toFixed(2)

  let AV153 = Number(Number(P41) * Number(L50))
  let AV153Value = isNaN(AV153) ? '-' : Number(AV153).toFixed(2)

  let H156 = Number(Number(H110) * Number(H41))
  let H156Value = isNaN(H156) ? '-' : Number(H156 / 100).toFixed(2)

  let J156 = Number(Number(H110) * Number(J41))
  let J156Value = isNaN(J156) ? '-' : Number(J156 / 100).toFixed(2)

  let L156 = Number(Number(H110) * Number(AR35))
  let L156Value = isNaN(L156) ? '-' : Number(L156 / 100).toFixed(2)

  let N156 = Number(Number(H110) * Number(AH6))
  let N156Value = isNaN(N156) ? '-' : Number(N156 / 100).toFixed(2)

  let P156 = Number(Number(H110) * Number(AV35))
  let P156Value = isNaN(P156) ? '-' : Number(P156 / 100).toFixed(2)

  let R156 = Number(Number(H110) * Number(J50))
  let R156Value = isNaN(R156) ? '-' : Number(R156 / 100).toFixed(2)

  let T156 = Number(Number(H110) * Number(J54))
  let T156Value = isNaN(T156) ? '-' : Number(T156 / 100).toFixed(2)

  let V156 = Number(Number(H110) * Number(T35))
  let V156Value = isNaN(V156) ? '-' : Number(V156 / 100).toFixed(2)

  let X156 = Number(Number(H110) * Number(AD35))
  let X156Value = isNaN(X156) ? '-' : Number(X156 / 100).toFixed(2)

  let Z156 = Number(Number(H110) * Number(L35))
  let Z156Value = isNaN(Z156) ? '-' : Number(Z156 / 100).toFixed(2)

  let AB156 = Number(Number(H110) * Number(X35))
  let AB156Value = isNaN(AB156) ? '-' : Number(AB156 / 100).toFixed(2)

  let AD156 = Number(Number(H110) * Number(AH35))
  let AD156Value = isNaN(AD156) ? '-' : Number(AD156 / 100).toFixed(2)

  let AF156 = Number(Number(H110) * Number(N35))
  let AF156Value = isNaN(AF156) ? '-' : Number(AF156 / 100).toFixed(2)

  let AH156 = Number(Number(H110) * Number(Z35))
  let AH156Value = isNaN(AH156) ? '-' : Number(AH156 / 100).toFixed(2)

  let AJ156 = Number(Number(H110) * Number(AJ35))
  let AJ156Value = isNaN(AJ156) ? '-' : Number(AJ156 / 100).toFixed(2)

  let AN156 = Number(Number(AB110) * Number(H41))
  let AN156Value = isNaN(AN156) ? '-' : Number(AN156).toFixed(2)

  let AP156 = Number(Number(X110) * Number(J41))
  let AP156Value = isNaN(AP156) ? '-' : Number(AP156).toFixed(2)

  let AR156 = Number(Number(X110) * Number(AR35))
  let AR156Value = isNaN(AR156) ? '-' : Number(AR156).toFixed(2)

  let AT156 = Number(Number(X110) * Number(AH6))
  let AT156Value = isNaN(AT156) ? '-' : Number(AT156).toFixed(2)

  let AV156 = Number(Number(X110) * Number(AV35))
  let AV156Value = isNaN(AV156) ? '-' : Number(AV156).toFixed(2)

  let AX156 = Number(Number(X110) * Number(J50))
  let AX156Value = isNaN(AX156) ? '-' : Number(AX156).toFixed(2)

  let H159 = Number(Number(X110) * Number(J54))
  let H159Value = isNaN(H159) ? '-' : Number(H159).toFixed(2)

  let J159 = Number(Number(X110) * Number(AF6))
  let J159Value = isNaN(J159) ? '-' : Number(J159).toFixed(2)

  let L159 = Number(Number(X110) * Number(AD35))
  let L159Value = isNaN(L159) ? '-' : Number(L159).toFixed(2)

  let N159 = Number(Number(X110) * Number(L35))
  let N159Value = isNaN(N159) ? '-' : Number(N159).toFixed(2)

  let P159 = Number(Number(X110) * Number(X35))
  let P159Value = isNaN(P159) ? '-' : Number(P159).toFixed(2)

  let R159 = Number(Number(X110) * Number(AH35))
  let R159Value = isNaN(R159) ? '-' : Number(R159).toFixed(2)

  let T159 = Number(Number(X110) * Number(N35))
  let T159Value = isNaN(T159) ? '-' : Number(T159).toFixed(2)

  let V159 = Number(Number(X110) * Number(Z35))
  let V159Value = isNaN(V159) ? '-' : Number(V159).toFixed(2)

  let X159 = Number(Number(X110) * Number(AJ35))
  let X159Value = isNaN(X159) ? '-' : Number(X159).toFixed(2)

  let AB159 = Number(Number(N110) * Number(V10))
  let AB159Value = isNaN(AB159) ? '-' : Number(AB159 / 100).toFixed(2)

  let AD159 = Number(Number(N110) * Number(J41))
  let AD159Value = isNaN(AD159) ? '-' : Number(AD159 / 100).toFixed(2)

  let AF159 = Number(Number(N110) * Number(AR35))
  let AF159Value = isNaN(AF159) ? '-' : Number(AF159 / 100).toFixed(2)

  let AH159 = Number(Number(N110) * Number(AH6))
  let AH159Value = isNaN(AH159) ? '-' : Number(AH159 / 100).toFixed(2)

  let AJ159 = Number(Number(N110) * Number(AV35))
  let AJ159Value = isNaN(AJ159) ? '-' : Number(AJ159 / 100).toFixed(2)

  let AL159 = Number(Number(N110) * Number(J50))
  let AL159Value = isNaN(AL159) ? '-' : Number(AL159 / 100).toFixed(2)

  let AN159 = Number(Number(N110) * Number(J54))
  let AN159Value = isNaN(AN159) ? '-' : Number(AN159 / 100).toFixed(2)

  let AP159 = Number(Number(N110) * Number(H35))
  let AP159Value = isNaN(AP159) ? '-' : Number(AP159 / 100).toFixed(2)

  let AR159 = Number(Number(N110) * Number(T35))
  let AR159Value = isNaN(AR159) ? '-' : Number(AR159 / 100).toFixed(2)

  let AT159 = Number(Number(N110) * Number(AD35))
  let AT159Value = isNaN(AT159) ? '-' : Number(AT159 / 100).toFixed(2)

  let AV159 = Number(Number(N110) * Number(L35))
  let AV159Value = isNaN(AV159) ? '-' : Number(AV159 / 100).toFixed(2)

  let AX159 = Number(Number(N110) * Number(X35))
  let AX159Value = isNaN(AX159) ? '-' : Number(AX159 / 100).toFixed(2)

  let H162 = Number(Number(N110) * Number(AH35))
  let H162Value = isNaN(H162) ? '-' : Number(H162 / 100).toFixed(2)

  let J162 = Number(Number(N110) * Number(N35))
  let J162Value = isNaN(J162) ? '-' : Number(J162 / 100).toFixed(2)

  let L162 = Number(Number(N110) * Number(Z35))
  let L162Value = isNaN(L162) ? '-' : Number(L162 / 100).toFixed(2)

  let N162 = Number(Number(N110) * Number(AJ35))
  let N162Value = isNaN(N162) ? '-' : Number(N162 / 100).toFixed(2)

  let R162 = Number(Number(R110) * Number(H41))
  let R162Value = isNaN(R162) ? '-' : Number(R162 / 100).toFixed(2)

  let T162 = Number(Number(R110) * Number(J41))
  let T162Value = isNaN(T162) ? '-' : Number(T162 / 100).toFixed(2)

  let V162 = Number(Number(R110) * Number(J84))
  let V162Value = isNaN(V162) ? '-' : Number(V162 / 10000).toFixed(2)

  let X162 = Number(Number(R110) * Number(AH6))
  let X162Value = isNaN(X162) ? '-' : Number(X162 / 100).toFixed(2)

  let Z162 = Number(Number(R110) * Number(AV35))
  let Z162Value = isNaN(Z162) ? '-' : Number(Z162 / 100).toFixed(2)

  let AB162 = Number(Number(R110) * Number(J50))
  let AB162Value = isNaN(AB162) ? '-' : Number(AB162 / 100).toFixed(2)

  let AD162 = Number(Number(R110) * Number(J54))
  let AD162Value = isNaN(AD162) ? '-' : Number(AD162 / 100).toFixed(2)

  let AH162 = Number(Number(R110) * Number(H35))
  let AH162Value = isNaN(AH162) ? '-' : Number(AH162 / 100).toFixed(2)

  let AJ162 = Number(Number(R110) * Number(T35))
  let AJ162Value = isNaN(AJ162) ? '-' : Number(AJ162 / 100).toFixed(2)

  let AL162 = Number(Number(P110) * Number(AD35))
  let AL162Value = isNaN(AL162) ? '-' : Number(AL162 / 100).toFixed(2)

  let AN162 = Number(Number(R110) * Number(L35))
  let AN162Value = isNaN(AN162) ? '-' : Number(AN162 / 100).toFixed(2)

  let AP162 = Number(Number(R110) * Number(X35))
  let AP162Value = isNaN(AP162) ? '-' : Number(AP162 / 100).toFixed(2)

  let AR162 = Number(Number(R110) * Number(AH35))
  let AR162Value = isNaN(AR162) ? '-' : Number(AR162 / 100).toFixed(2)

  let AT162 = Number(Number(R110) * Number(N35))
  let AT162Value = isNaN(AT162) ? '-' : Number(AT162 / 100).toFixed(2)

  let AV162 = Number(Number(P110) * Number(Z35))
  let AV162Value = isNaN(AV162) ? '-' : Number(AV162 / 100).toFixed(2)

  let AX162 = Number(Number(R110) * Number(AJ35))
  let AX162Value = isNaN(AX162) ? '-' : Number(AX162 / 100).toFixed(2)

  let J165 = Number(Number(P110) * Number(J84))
  let J165Value = isNaN(J165) ? '-' : Number(J165 / 10000).toFixed(2)

  let L165 = Number(Number(P110) * Number(AH6))
  let L165Value = isNaN(L165) ? '-' : Number(L165 / 100).toFixed(2)

  let N165 = Number(Number(P110) * Number(AF6))
  let N165Value = isNaN(N165) ? '-' : Number(N165 / 100).toFixed(2)

  let P165 = Number(Number(L110()) * Number(AX35))
  let P165Value = isNaN(P165) ? '-' : Number(P165).toFixed(2)

  let R165 = Number(Number(T110()) * Number(AX35))
  let R165Value = isNaN(R165) ? '-' : Number(R165).toFixed(2)

  let H170 = Number(N38)
  let H170Value = isNaN(H170) ? '-' : Number(H170 * 100).toFixed(2)

  let J170 = Number(Number(X72) * Number(V84))
  let J170Value = isNaN(J170) ? '-' : Number(J170 / 100).toFixed(2)

  let L170 = Number(Number(V72) * Number(X84))
  let L170Value = isNaN(L170) ? '-' : Number(L170 / 100).toFixed(2)

  let N170 = Number(Number(X72) * Number(X84))
  let N170Value = isNaN(N170) ? '-' : Number(N170 / 100).toFixed(2)

  let P170 = Number(Number(Z72) * Number(V84))
  let P170Value = isNaN(P170) ? '-' : Number(P170 / 100).toFixed(2)

  let R170 = Number(Number(V72) * Number(Z84))
  let R170Value = isNaN(R170) ? '-' : Number(R170 / 100).toFixed(2)

  let T170 = Number(Number(Z72) * Number(X84))
  let T170Value = isNaN(T170) ? '-' : Number(T170 / 100).toFixed(2)

  let V170 = Number(Number(X72) * Number(Z84))
  let V170Value = isNaN(V170) ? '-' : Number(V170 / 100).toFixed(2)

  let X170 = Number(Number(AB72) * Number(V84))
  let X170Value = isNaN(X170) ? '-' : Number(X170 / 100).toFixed(2)

  let Z170 = Number(Number(V72) * Number(AB84))
  let Z170Value = isNaN(Z170) ? '-' : Number(Z170 / 100).toFixed(2)

  let AB170 = Number(Number(Z72) * Number(Z84))
  let AB170Value = isNaN(AB170) ? '-' : Number(AB170 / 100).toFixed(2)

  let AD170 = Number(Number(AV72) * Number(V84))
  let AD170Value = isNaN(AD170) ? '-' : Number(AD170 / 100).toFixed(2)

  let AF170 = Number(Number(V72) * Number(AV84))
  let AF170Value = isNaN(AF170) ? '-' : Number(AF170 / 100).toFixed(2)

  let AH170 = Number(Number(AB72) * Number(X84))
  let AH170Value = isNaN(AH170) ? '-' : Number(AH170 / 100).toFixed(2)

  let AJ170 = Number(Number(X72) * Number(AB84))
  let AJ170Value = isNaN(AJ170) ? '-' : Number(AJ170 / 100).toFixed(2)

  let AL170 = Number(Number(AB72) * Number(Z84))
  let AL170Value = isNaN(AL170) ? '-' : Number(AL170 / 100).toFixed(2)

  let AN170 = Number(Number(Z72) * Number(AB84))
  let AN170Value = isNaN(AN170) ? '-' : Number(AN170 / 100).toFixed(2)

  let AP170 = Number(Number(AV72) * Number(X84))
  let AP170Value = isNaN(AP170) ? '-' : Number(AP170 / 100).toFixed(2)

  let AR170 = Number(Number(X72) * Number(AV84))
  let AR170Value = isNaN(AR170) ? '-' : Number(AR170 / 100).toFixed(2)

  let AT170 = Number(Number(AX72) * Number(V84))
  let AT170Value = isNaN(AT170) ? '-' : Number(AT170 / 100).toFixed(2)

  let AV170 = Number(Number(V72) * Number(AX84))
  let AV170Value = isNaN(AV170) ? '-' : Number(AV170 / 100).toFixed(2)

  let AX170 = Number(Number(AB72) * Number(AB84))
  let AX170Value = isNaN(AX170) ? '-' : Number(AX170 / 100).toFixed(2)

  let H173 = Number(Number(AV72) * Number(Z84))
  let H173Value = isNaN(H173) ? '-' : Number(H173 / 100).toFixed(2)

  let J173 = Number(Number(Z72) * Number(AV84))
  let J173Value = isNaN(J173) ? '-' : Number(J173 / 100).toFixed(2)

  let L173 = Number(Number(AX72) * Number(X84))
  let L173Value = isNaN(L173) ? '-' : Number(L173 / 100).toFixed(2)

  let N173 = Number(Number(X72) * Number(AX84))
  let N173Value = isNaN(N173) ? '-' : Number(N173 / 100).toFixed(2)

  let T173 = Number(
    100 -
      (Number(H170 * 100) +
        Number(J170 / 100) +
        Number(L170 / 100) +
        Number(N170 / 100) +
        Number(P170 / 100) +
        Number(R170 / 100) +
        Number(T170 / 100) +
        Number(V170 / 100) +
        Number(X170 / 100) +
        Number(Z170 / 100) +
        Number(AB170 / 100) +
        Number(AD170 / 100) +
        Number(AF170 / 100) +
        Number(AH170 / 100) +
        Number(AJ170 / 100) +
        Number(AL170 / 100) +
        Number(AN170 / 100) +
        Number(AP170 / 100) +
        Number(AR170 / 100) +
        Number(AT170 / 100) +
        Number(AV170 / 100) +
        Number(AX170 / 100) +
        Number(H173 / 100) +
        Number(J173 / 100) +
        Number(L173 / 100) +
        Number(N173 / 100))
  )
  let T173Value = isNaN(T173) ? '-' : Number(T173).toFixed(2)

  let H30 = Number(Number(J170) + Number(T170) + Number(AL170))
  let H30Value = isNaN(H30) ? '-' : Number(H30 / 100).toFixed(2)

  let J30 = Number(Number(P170) + Number(AH170) + Number(H173))
  let J30Value = isNaN(J30) ? '-' : Number(J30 / 100).toFixed(2)

  let L30 = Number(Number(X170) + Number(AP170) + Number(L173) + Number(AD170))
  let L30Value = isNaN(L30) ? '-' : Number(L30 / 100).toFixed(2)

  let N30 = Number(Number(L170) + Number(V170) + Number(AN170))
  let N30Value = isNaN(N30) ? '-' : Number(N30 / 100).toFixed(2)

  let P30 = Number(Number(R170) + Number(AJ170) + Number(J173))
  let P30Value = isNaN(P30) ? '-' : Number(P30 / 100).toFixed(2)

  let R30 = Number(
    Number(Z170) + Number(AF170) + Number(AR170) + Number(AV170) + Number(N173)
  )
  let R30Value = isNaN(R30) ? '-' : Number(R30 / 100).toFixed(2)

  let H121 = Number(J30)
  let H121Value = isNaN(H121) ? '-' : Number(H121 / 100).toFixed(2)

  let J121 = Number(H30)
  let J121Value = isNaN(J121) ? '-' : Number(J121 / 100).toFixed(2)

  let L121 = Number(P30)
  let L121Value = isNaN(L121) ? '-' : Number(L121 / 100).toFixed(2)

  let H124 = Number(L30)
  let H124Value = isNaN(H124) ? '-' : Number(H124 / 100).toFixed(2)

  let J124 = Number(J30)
  let J124Value = isNaN(J124) ? '-' : Number(J124 / 100).toFixed(2)

  let L124 = Number(R30)
  let L124Value = isNaN(L124) ? '-' : Number(L124 / 100).toFixed(2)

  let AL156 = Number(Number(H110) * Number(H121))
  let AL156Value = isNaN(AL156) ? '-' : Number(AL156 / 1000000).toFixed(2)

  let P162 = Number(Number(N110) * Number(H121))
  let P162Value = isNaN(P162) ? '-' : Number(P162 / 1000000).toFixed(2)

  let Z159 = Number(Number(X110) * Number(L124))
  let Z159Value = isNaN(Z159) ? '-' : Number(Z159 / 10000).toFixed(2)

  let H165 = Number(Number(R110) * Number(L124))
  let H165Value = isNaN(H165) ? '-' : Number(H165 / 1000000).toFixed(2)

  let H176 = Number(Number(P75) * Number(P87))
  let H176Value = isNaN(H176) ? '-' : Number(H176 / 100).toFixed(2)

  let J176 = Number(Number(R75) * Number(P87))
  let J176Value = isNaN(J176) ? '-' : Number(J176 / 100).toFixed(2)

  let L176 = Number(Number(P75) * Number(R87))
  let L176Value = isNaN(L176) ? '-' : Number(L176 / 100).toFixed(2)

  let N176 = Number(Number(R75) * Number(R87))
  let N176Value = isNaN(N176) ? '-' : Number(N176 / 100).toFixed(2)

  let P176 = Number(Number(T75) * Number(P87))
  let P176Value = isNaN(P176) ? '-' : Number(P176 / 100).toFixed(2)

  let R176 = Number(Number(P75) * Number(T87))
  let R176Value = isNaN(R176) ? '-' : Number(R176 / 100).toFixed(2)

  let T176 = Number(Number(T75) * Number(R87))
  let T176Value = isNaN(T176) ? '-' : Number(T176 / 100).toFixed(2)

  let V176 = Number(Number(X72) * Number(Z84))
  let V176Value = isNaN(V176) ? '-' : Number(V176 / 100).toFixed(2)

  let X176 = Number(Number(AB72) * Number(P87))
  let X176Value = isNaN(X176) ? '-' : Number(X176 / 100).toFixed(2)

  let Z176 = Number(Number(P75) * Number(AD87))
  let Z176Value = isNaN(Z176) ? '-' : Number(Z176 / 100).toFixed(2)

  let AB176 = Number(Number(T75) * Number(T87))
  let AB176Value = isNaN(AB176) ? '-' : Number(AB176 / 100).toFixed(2)

  let AD176 = Number(Number(AD75) * Number(R87))
  let AD176Value = isNaN(AD176) ? '-' : Number(AD176 / 100).toFixed(2)

  let AF176 = Number(Number(R75) * Number(AD87))
  let AF176Value = isNaN(AF176) ? '-' : Number(AF176 / 100).toFixed(2)

  let AH176 = Number(Number(AD75) * Number(T87))
  let AH176Value = isNaN(AH176) ? '-' : Number(AH176 / 100).toFixed(2)

  let AJ176 = Number(Number(T75) * Number(AD87))
  let AJ176Value = isNaN(AJ176) ? '-' : Number(AJ176 / 100).toFixed(2)

  let AL176 = Number(Number(AD75) * Number(AD87))
  let AL176Value = isNaN(AL176) ? '-' : Number(AL176 / 100).toFixed(2)

  let sumall176 =
    Number(H176 / 100) +
    Number(J176 / 100) +
    Number(L176 / 100) +
    Number(N176 / 100) +
    Number(P176 / 100) +
    Number(R176 / 100) +
    Number(T176 / 100) +
    Number(V176 / 100) +
    Number(X176 / 100) +
    Number(Z176 / 100) +
    Number(AB176 / 100) +
    Number(AD176 / 100) +
    Number(AF176 / 100) +
    Number(AH176 / 100) +
    Number(AJ176 / 100) +
    Number(AL176 / 100)

  let AN176 = () => {
    if (sumall176 < 0) {
      let result = 100 - sumall176
      return isNaN(result) ? '-' : Number(result).toFixed(2)
    } else {
      let result = sumall176 - 100
      return isNaN(result) ? '-' : Number(result).toFixed(2)
    }
  }

  let N44 = Number(H50)
  let N44Value = isNaN(N44) ? '-' : Number(N44 * 100).toFixed(2)

  let R44 = Number((Number(Z50) + Number(H53)) / 2)
  let R44Value = isNaN(R44) ? '-' : Number(R44 * 100).toFixed(2)

  let V44 = Number(H75)
  let V44Value = isNaN(V44) ? '-' : Number(V44).toFixed(2)

  let X44 = Number(H87)
  let X44Value = isNaN(X44) ? '-' : Number(X44).toFixed(2)

  let Z44 = Number((Number(P75) + Number(H78)) / 2).toFixed(2)
  let Z44Value = isNaN(Z44) ? '-' : Number(Z44).toFixed(2)

  let AB44 = Number((Number(P87) + Number(H90)) / 2)
  let AB44Value = isNaN(AB44) ? '-' : Number(AB44).toFixed(2)

  return (
    <div className={classes.container}>
      <Grupa1
        imgText='FUDBAL - SVE IGRE'
        imgBackgroundColorDark='#0C7C3F'
        imgBackgroundColorLight='#14CB67'
        imgFontColor='whitesmoke'
        label1='VREME POČETKA'
        label2='ZEMLJA'
        label3='DOMAĆIN'
        label4='TAKMIČENJE - LIGA'
        label5='GOST'
      />
      <Grupa20
        imgBackgroundColorDark='#0C7C3F'
        imgBackgroundColorLight='#14CB67'
        imgFontColor='whitesmoke'
        calculatorType={calculatorType}
      />

      <div className={classes.header}>
        <div className={classes.title}>Upis kvota</div>
        <div className={classes.inputiKvota}>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'purple',
                color: 'white',
              }}
            >
              1
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '92px',
              }}
              value={B8}
              onChange={(e) => setB8(e.target.value)}
            />
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {B10Value} {isNaN(B10Value) ? null : '%'}
            </div>
          </div>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'purple',
                color: 'white',
              }}
            >
              X
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '92px',
              }}
              value={D8}
              onChange={(e) => setD8(e.target.value)}
            />
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {D10Value}
              {isNaN(D10Value) ? null : '%'}
            </div>
          </div>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'purple',
                color: 'white',
              }}
            >
              2
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '92px',
              }}
              value={F8}
              onChange={(e) => setF8(e.target.value)}
            />
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {F10Value}
              {isNaN(F10Value) ? null : '%'}
            </div>
          </div>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'green',
                color: 'white',
              }}
            >
              T 0
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '92px',
              }}
              value={H8}
              onChange={(e) => setH8(e.target.value)}
            />
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {H10Value} {isNaN(H10Value) ? null : '%'}
            </div>
          </div>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'green',
                color: 'white',
              }}
            >
              T 1
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '92px',
              }}
              value={J8}
              onChange={(e) => setJ8(e.target.value)}
            />

            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {J10Value} {isNaN(J10Value) ? null : '%'}
            </div>
          </div>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'green',
                color: 'white',
              }}
            >
              T 2
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '92px',
              }}
              value={L8}
              onChange={(e) => setL8(e.target.value)}
            />

            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {L10Value}
              {isNaN(L10Value) ? null : '%'}
            </div>
          </div>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'green',
                color: 'white',
              }}
            >
              T 3
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '92px',
              }}
              value={N8}
              onChange={(e) => setN8(e.target.value)}
            />
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {N10Value}
              {isNaN(N10Value) ? null : '%'}
            </div>
          </div>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'green',
                color: 'white',
              }}
            >
              T 4
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '92px',
              }}
              value={P8}
              onChange={(e) => setP8(e.target.value)}
            />
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {P10Value}
              {isNaN(P10Value) ? null : '%'}
            </div>
          </div>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'green',
                color: 'white',
              }}
            >
              T 5
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '92px',
              }}
              value={R8}
              onChange={(e) => setR8(e.target.value)}
            />
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {R10Value}
              {isNaN(R10Value) ? null : '%'}
            </div>
          </div>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'green',
                color: 'white',
              }}
            >
              UG 6+
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '120px',
              }}
              value={T8}
              onChange={(e) => setT8(e.target.value)}
            />
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {T10Value}
              {isNaN(T10Value) ? null : '%'}
            </div>
          </div>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'orange',
                color: 'white',
              }}
            >
              GG
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '120px',
              }}
              value={V8}
              onChange={(e) => setV8(e.target.value)}
            />
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {V10Value}
              {isNaN(V10Value) ? null : '%'}
            </div>
          </div>
          <div>
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'orange',
                color: 'white',
              }}
            >
              NE GG
            </div>
            <input
              style={{
                fontWeight: 'bold',
                fontSize: '20px',
                height: '33%',
                width: '120px',
              }}
              value={X8}
              onChange={(e) => setX8(e.target.value)}
            />
            <div
              style={{
                height: '33%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: 'blue',
                color: 'white',
              }}
            >
              {X10Value}
              {isNaN(X10Value) ? null : '%'}
            </div>
          </div>
        </div>
      </div>

      <div className={classes.procenti}>
        <div>
          <div>1</div>
          <div
            style={{ backgroundColor: 'blue', color: 'white', width: '170px' }}
            className={classes.procenticalc}
          >
            {B10Value}
            {isNaN(B10Value) ? null : '%'}
          </div>
        </div>
        <div>
          <div>X</div>
          <div
            style={{ backgroundColor: 'blue', color: 'white', width: '170px' }}
            className={classes.procenticalc}
          >
            {D10Value}
            {isNaN(D10Value) ? null : '%'}
          </div>
        </div>
        <div>
          <div>2</div>
          <div
            style={{ backgroundColor: 'blue', color: 'white', width: '170px' }}
            className={classes.procenticalc}
          >
            {F10Value}
            {isNaN(F10Value) ? null : '%'}
          </div>
        </div>
        <div>
          <div>UG 0-2</div>
          <div
            style={{ backgroundColor: 'blue', color: 'white', width: '170px' }}
            className={classes.procenticalc}
          >
            {AF6Value}
            {isNaN(AF6Value) ? null : '%'}
          </div>
        </div>
        <div>
          <div>UG 3+</div>
          <div
            style={{ backgroundColor: 'blue', color: 'white', width: '170px' }}
            className={classes.procenticalc}
          >
            {AH6Value}
            {isNaN(AH6Value) ? null : '%'}
          </div>
        </div>
        <div>
          <div>SREDNJI BROJ GOLOVA</div>
          <div className={classes.procenticalc}>
            <div style={{ backgroundColor: 'red' }}>{AJ6Value}</div>
            <div style={{ backgroundColor: '#FFC000' }}>{AJ8Value}</div>
            <div style={{ backgroundColor: '#38F81E' }}>{AJ9Value}</div>
          </div>
        </div>
        <div>
          <div>SREDNJI TR</div>
          <div className={classes.procenticalc}>
            <div style={{ backgroundColor: 'red' }}>{AP6}</div>
            <div style={{ backgroundColor: '#FFC000' }}>{AP8}</div>
            <div style={{ backgroundColor: '#38F81E' }}>{AP9}</div>
          </div>
        </div>
        <div>
          <div>SREDNJI TR-GOL.DECIMALA</div>
          <div className={classes.procenticalc}>
            <div style={{ backgroundColor: 'red' }}>{AT6}</div>
            <div style={{ backgroundColor: '#FFC000' }}>{AT8}</div>
            <div style={{ backgroundColor: '#38F81E' }}>{AT9}</div>
          </div>
        </div>
      </div>
      <Title
        sty
        background='rgb(94,182,64)'
        color='white'
        title='KONAČAN ISHOD'
      />
      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          title='KONAČAN ISHOD'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='1'
          procenat={H18Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='X'
          procenat={J18Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='2'
          procenat={L18Value}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          title='DUPLA ŠANSA'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='1X'
          procenat={H21Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='12'
          procenat={J21Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='X2'
          procenat={L21Value}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          color='white'
          color='white'
          color='white'
          color='white'
          title='DUPLA POBEDA'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='DP 1'
          procenat={H24Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='DP 2'
          procenat={J24Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='SPD 1'
          procenat={L24Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='SPD 2'
          procenat={N24Value}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          title='POLUVREME ILI KRAJ'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='P ILI K 1'
          procenat={H27Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='P IKI K X'
          procenat={J27Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='P ILI K 2'
          procenat={L27Value}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          color='white'
          color='white'
          color='white'
          title='POBEDA SA RAZLIKOM'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='DW 1'
          procenat={H30Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='DW 2'
          procenat={J30Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='DW 3+'
          procenat={L30Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='GW 1'
          procenat={N30Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='GW 2'
          procenat={P30Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='GW 3+'
          procenat={R30Value}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          color='white'
          color='white'
          color='white'
          title='PRVO POLUVREME'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='P 1'
          procenat={Z18()}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='P X'
          procenat={AB18Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='P 2'
          procenat={AD18()}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          color='white'
          color='white'
          title='DUPLA ŠANSA PRVO POLUVREME'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='P 1X'
          procenat={Z21Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='P 12'
          procenat={AB21Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='P X2'
          procenat={AD21Value}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          color='white'
          color='white'
          title='SUPER POBEDA'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='SP 1'
          procenat={Z24Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='SP 2'
          procenat={AB24Value}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          color='white'
          title='DRUGO POLUVREME'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='DP 1'
          procenat={AR18()}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='DP X'
          procenat={AT18value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='DP 2'
          procenat={AV18()}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          color='white'
          title='DUPLA ŠANSA DRUGO POLUVREME'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='DP 1X'
          procenat={AR21Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='DP 12'
          procenat={AT21Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='DP X2'
          procenat={AV21Value}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          color='white'
          title='WINER'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='W 1'
          procenat={AR24Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='W 2'
          procenat={AT24Value}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          title='WINER PRVO POLUVREME'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='WP 1'
          procenat={AR27Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='WP 2'
          procenat={AT27Value}
        />
      </div>

      <div className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(94,182,64)'
          color='white'
          title='WINER DRUGO POLUVREME'
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='WDP 1'
          procenat={AR30Value}
        />
        <Igra
          background='rgb(94,182,64)'
          color='white'
          igra='WDP 2'
          procenat={AT30Value}
        />
      </div>
      <Title background='rgb(78,173,91)' color='white' title='IGRE NA GOLOVE' />
      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='UKUPNO GOLOVA'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 0-1'
          procenat={H35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 1-2'
          procenat={J35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 2-3'
          procenat={L35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 3-3'
          procenat={N35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 4-5'
          procenat={P35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 5-6'
          procenat={R35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 0-2'
          procenat={T35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 1-3'
          procenat={V35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 2-4'
          procenat={X35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 3-5'
          procenat={Z35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 4-6'
          procenat={AB35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 0-3'
          procenat={AD35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 1-4'
          procenat={AF35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 2-5'
          procenat={AH35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 3-6'
          procenat={AJ35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 0-4'
          procenat={AL35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 1-5'
          procenat={AN35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 2-6'
          procenat={AP35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 2+'
          procenat={AR35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 3+'
          procenat={AT35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 4+'
          procenat={AV35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 5+'
          procenat={AX35Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 6+'
          procenat={H38Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='UG 7+'
          procenat={J38Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='T 0'
          procenat={N38Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='T 1'
          procenat={P38Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='T 2'
          procenat={R38Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='T 3'
          procenat={T38Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='T 4'
          procenat={V38Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='T 5'
          procenat={X38Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='T 6'
          procenat={Z38Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='OBA TIMA DAJU GOL GG - NEGG'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GG'
          procenat={H41Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='NEGG'
          procenat={J41Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GG 1'
          procenat={L41Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='NE GG 1'
          procenat={N41Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GG2'
          procenat={P41Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='NE GG 2'
          procenat={R41Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='PRVI DAJE GOL'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PG 1'
          procenat={H44Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PG X'
          procenat={J44Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PG 2'
          procenat={L44Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP PG'
          procenat={N44Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP PG'
          procenat={R44Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP PGD'
          procenat={V44Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP PGG'
          procenat={X44Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP PGD'
          procenat={Z44Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP PGG'
          procenat={AB44Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='ZADNJI DAJE GOL'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='ZG 1'
          procenat={H47Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='ZG 2'
          procenat={J47Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='UKUPNO GOLOVA PRVO POLUVREME'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP 1+'
          procenat={H50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP 2+'
          procenat={J50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP 3+'
          procenat={L50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP 4+'
          procenat={N50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP 0-1'
          procenat={P50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP 0-2'
          procenat={R50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP 1-2'
          procenat={T50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP 1-3'
          procenat={V50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP 2-3'
          procenat={X50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PT 0'
          procenat={Z50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PT 1'
          procenat={AB50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PT 2'
          procenat={AD50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PT 3'
          procenat={AF50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP NEG1'
          procenat={AH50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PP NEG2'
          procenat={AJ50Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='PPGX'
          procenat={AL50Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='UKUPNO GOLOVA DRUGO POLUVREME'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP 1+'
          procenat={H53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP 2+'
          procenat={J53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP 3+'
          procenat={L53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP 4+'
          procenat={N53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP 0-1'
          procenat={P53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP 0-2'
          procenat={R53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP 1-2'
          procenat={T53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP 1-3'
          procenat={V53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP 2-3'
          procenat={X53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DPT 0'
          procenat={Z53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DPT 1'
          procenat={AB53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DPT 2'
          procenat={AD53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DPT 3'
          procenat={AF53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP NEG1'
          procenat={AH53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DP NEG2'
          procenat={AJ53Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DPGX'
          procenat={AL53Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='KOMBINACIJE GOLOVA'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1+&2+'
          procenat={H57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1+&3+'
          procenat={J57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1+&2P1+'
          procenat={L57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1+&2P2+'
          procenat={P57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1+&2P3+'
          procenat={T57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1+&2P0-1'
          procenat={X57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-1&2P0-2'
          procenat={AB57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1+&2P1-2'
          procenat={AF57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1+&2P1-3'
          procenat={AJ57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1+&2P2-3'
          procenat={AN57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1+&2P2-3'
          procenat={AR57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-1&2P2+'
          procenat={AV57Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-1&2P0-1'
          procenat={H60Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-1&2P0-3'
          procenat={L60Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-1&2P1-2'
          procenat={P60Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-1&2P1-3'
          procenat={T60Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-1&2P2-3'
          procenat={X60Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2+&2P1+'
          procenat={AB60Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2+&2P2+'
          procenat={AF60Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2+&2P3+'
          procenat={AJ60Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2+&2P4+'
          procenat={AN60Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2+&2P0-1'
          procenat={AR60Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2+&2P2-3'
          procenat={AV60Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-2&2P2+'
          procenat={H63Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-2&2P0-1'
          procenat={L63Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-2&2P0-2'
          procenat={P63Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-2&2P0-3'
          procenat={T63Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-2&2P1-2'
          procenat={X63Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-2&2P1-3'
          procenat={AB63Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P0-2&2P2-3'
          procenat={AF63Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-2&2P1+'
          procenat={AJ63Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-2&2P2+'
          procenat={AN63Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-2&2P0-1'
          procenat={AR63Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-2&2P0-2'
          procenat={AV63Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-2&2P0-3'
          procenat={H66Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-2&2P3+'
          procenat={L66Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-2&2P4+'
          procenat={P66Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-3&2P3+'
          procenat={T66Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-3&2P1+'
          procenat={X66Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-3&2P2+'
          procenat={AB66Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-3&2P1-2'
          procenat={AF66Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P1-3&2P1-3'
          procenat={AJ66Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2-3&2P1+'
          procenat={AN66Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2-3&2P2+'
          procenat={AR66Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2-3&2P3+'
          procenat={AV66Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2-3&2P4+'
          procenat={H69Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2-3&2P0-1'
          procenat={L69Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2-3&2P0-2'
          procenat={P69Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2-3&2P1-3'
          procenat={T69Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2-3&2P1-2'
          procenat={X69Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1P2-3&2P2-3'
          procenat={AB69Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          title='DOMAĆIN GOLOVI'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 1+'
          procenat={H72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 2+'
          procenat={J72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 3+'
          procenat={L72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 4+'
          procenat={N72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 0-1'
          procenat={P72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 0-2'
          procenat={R72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 0-3'
          procenat={T72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 0'
          procenat={V72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DT 1'
          procenat={X72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DT 2'
          procenat={Z72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DT 3'
          procenat={AB72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 1-2'
          procenat={AD72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 1-3'
          procenat={AF72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 2-3'
          procenat={AH72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 2-4'
          procenat={AJ72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 2-5'
          procenat={AL72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 2-6'
          procenat={AN72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 3-4'
          procenat={AP72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 3-5'
          procenat={AR72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D 4-6'
          procenat={AT72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DT 4'
          procenat={AV72Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DT 5'
          procenat={AX72Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='DOMAĆIN GOLOVI PRVO POLUVREME'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D 1+'
          procenat={H75Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D 2+'
          procenat={J75Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D 3+'
          procenat={L75Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D 4+'
          procenat={N75()}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D 0'
          procenat={P75Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1DT 1'
          procenat={R75Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1DT 2'
          procenat={T75Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D 0-1'
          procenat={V75Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D 1-2'
          procenat={X75Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D 2-3'
          procenat={Z75Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1PPG 1'
          procenat={AB75Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1DT 3'
          procenat={AD75Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='DOMAĆIN GOLOVI DRUGO POLUVREME'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2D 1+'
          procenat={H78Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2D 2+'
          procenat={J78Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2D 3+'
          procenat={L78Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2D 4+'
          procenat={N78Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2D 0'
          procenat={P78Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2DT 1'
          procenat={R78Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2DT 2'
          procenat={T78Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2D 0-1'
          procenat={V78Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2D 1-2'
          procenat={X78Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2D 2-3'
          procenat={Z78Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2PPG 1'
          procenat={AB78value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='DOMAĆIN GOLOVI MIKS GOLOVA'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D1+ & D2+'
          procenat={H81Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D1+ & D3+'
          procenat={L81Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D1+ & 2D2+'
          procenat={P81Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D 2+ & 2D1+'
          procenat={T81Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D 2+ & 2D2+'
          procenat={X81Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D0-1 & 2D0-1'
          procenat={AB81Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D0-1 & 2D0-2'
          procenat={AF81Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D0-2 & 2D0-1'
          procenat={AJ81Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D0-2 & 2D0-2'
          procenat={AN81Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1D1 & 4+'
          procenat={AR81Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='GOST GOLOVI'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 1+'
          procenat={H84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 2+'
          procenat={J84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 3+'
          procenat={L84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 4+'
          procenat={N84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 0-1'
          procenat={P84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 0-2'
          procenat={R84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 0-3'
          procenat={T84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 0'
          procenat={V84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GT 1'
          procenat={X84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GT 2'
          procenat={Z84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GT 3'
          procenat={AB84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 1-2'
          procenat={AD84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 1-3'
          procenat={AF84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 2-3'
          procenat={AH84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 2-4'
          procenat={AJ84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 2-5'
          procenat={AL84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 2-6'
          procenat={AN84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 3-4'
          procenat={AP84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 3-5'
          procenat={AR84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='G 4-6'
          procenat={AT84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GT 4'
          procenat={AV84Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GT 5'
          procenat={AX84Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='GOST GOLOVI PRVO POLUVREME'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G 1+'
          procenat={H87Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G 2+'
          procenat={J87Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G 3+'
          procenat={L87Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G 4+'
          procenat={N87()}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G 0'
          procenat={P87Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1GT 1'
          procenat={R87Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1GT 2'
          procenat={T87Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G 0-1'
          procenat={V87Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G 1-2'
          procenat={X87Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G 2-3'
          procenat={Z87Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1PPG 1'
          procenat={AB87Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1GT 3'
          procenat={AD87Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='GOST GOLOVI DRUGO POLUVREME'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2G 1+'
          procenat={H90Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2G 2+'
          procenat={J90Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2G 3+'
          procenat={L90Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2G 4+'
          procenat={N90Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2G 0'
          procenat={P90Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2GT 1'
          procenat={R90Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2GT 2'
          procenat={T90Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2G 0-1'
          procenat={V90Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2G 1-2'
          procenat={X90Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2G 2-3'
          procenat={Z90Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2PPG 1'
          procenat={AB90Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='GOST GOLOVI MIKS GOLOVA'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G1+ & G2+'
          procenat={H93Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G1+ & G3+'
          procenat={L94Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G1+ & 2G2+'
          procenat={P93Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G 2+ & 2G1+'
          procenat={T93Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G 2+ & 2G2+'
          procenat={X93Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G0-1 & 2G0-1'
          procenat={AB93Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G0-1 & 2G0-2'
          procenat={AF93Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G0-2 & 2G0-1'
          procenat={AJ93Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G0-2 & 2G0-2'
          procenat={AN93Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1G1 & 4+'
          procenat={AR93Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='MIKS - GG'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GG 3+'
          procenat={H96Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GG 4+'
          procenat={J96Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='D2+&G2+'
          procenat={L96Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GG&D2+'
          procenat={N96Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GG&G2+'
          procenat={P96Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='GG&2-3'
          procenat={R96Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='NEGG3+'
          procenat={T96Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='DAJE NE DAJE GOL I i II P DOMAĆIN'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DGD'
          procenat={H99Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='NGD'
          procenat={J99Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='DAJE NE DAJE GOL I i II P GOST'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='DGG'
          procenat={H102Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='NGG'
          procenat={J102Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(78,173,91)'
          color='white'
          color='white'
          title='PADA VIŠE GOLOVA'
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1 >'
          procenat={H105Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='1=2'
          procenat={J105Value}
        />
        <Igra
          background='rgb(78,173,91)'
          color='white'
          igra='2 >'
          procenat={L105Value}
        />
      </div>

      <Title background='rgb(159,206,99)' title='POLUVREME - KRAJ' />

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre background='rgb(159,206,99)' title='POLUVREME - KRAJ' />
        <Igra background='rgb(159,206,99)' igra='1-1' procenat={H110Value} />
        <Igra background='rgb(159,206,99)' igra='1-X' procenat={J110()} />
        <Igra background='rgb(159,206,99)' igra='1-2' procenat={L110()} />
        <Igra background='rgb(159,206,99)' igra='X-1' procenat={N110Value} />
        <Igra background='rgb(159,206,99)' igra='X-X' procenat={P110Value} />
        <Igra background='rgb(159,206,99)' igra='X-2' procenat={R110Value} />
        <Igra background='rgb(159,206,99)' igra='2-1' procenat={T110()} />
        <Igra background='rgb(159,206,99)' igra='2-X' procenat={V110()} />
        <Igra background='rgb(159,206,99)' igra='2-2' procenat={X110Value} />
        <Igra background='rgb(159,206,99)' igra='NE 1-1' procenat={Z110Value} />
        <Igra
          background='rgb(159,206,99)'
          igra='NE 2-2'
          procenat={AB110Value}
        />
        <Igra
          background='rgb(159,206,99)'
          igra='NE X-1'
          procenat={AD110Value}
        />
        <Igra
          background='rgb(159,206,99)'
          igra='NE X-X'
          procenat={AF110Value}
        />
        <Igra
          background='rgb(159,206,99)'
          igra='NE X-2'
          procenat={AH110Value}
        />
        <Igra
          background='rgb(159,206,99)'
          igra='NE 1-2'
          procenat={AJ110Value}
        />
        <Igra
          background='rgb(159,206,99)'
          igra='NE 1-X'
          procenat={AL110Value}
        />
        <Igra
          background='rgb(159,206,99)'
          igra='NE 2-1'
          procenat={AN110Value}
        />
        <Igra
          background='rgb(159,206,99)'
          igra='NE 2-X'
          procenat={AP110Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(159,206,99)'
          title='POLUVREME - KRAJ DUPLA ŠANSA'
        />
        <Igra background='rgb(159,206,99)' igra='1-1X' procenat={H113Value} />
        <Igra background='rgb(159,206,99)' igra='1-X2' procenat={J113Value} />
        <Igra background='rgb(159,206,99)' igra='1-12' procenat={L113Value} />
        <Igra background='rgb(159,206,99)' igra='2-1X' procenat={N113Value} />
        <Igra background='rgb(159,206,99)' igra='2-X2' procenat={P113Value} />
        <Igra background='rgb(159,206,99)' igra='2-12' procenat={R113Value} />
        <Igra background='rgb(159,206,99)' igra='X-1X' procenat={T113Value} />
        <Igra background='rgb(159,206,99)' igra='X-X2' procenat={V113Value} />
        <Igra background='rgb(159,206,99)' igra='X-12' procenat={X113Value} />
        <Igra background='rgb(159,206,99)' igra='1X-1' procenat={Z113Value} />
        <Igra background='rgb(159,206,99)' igra='1X-X' procenat={AB113Value} />
        <Igra background='rgb(159,206,99)' igra='1X-2' procenat={AD113Value} />
        <Igra background='rgb(159,206,99)' igra='X2-1' procenat={AF113Value} />
        <Igra background='rgb(159,206,99)' igra='X2-X' procenat={AH113Value} />
        <Igra background='rgb(159,206,99)' igra='X2-2' procenat={AJ113Value} />
        <Igra background='rgb(159,206,99)' igra='12-1' procenat={AL113Value} />
        <Igra background='rgb(159,206,99)' igra='12-X' procenat={AN113Value} />
        <Igra background='rgb(159,206,99)' igra='12-2' procenat={AP113Value} />
        <Igra background='rgb(159,206,99)' igra='1X-1X' procenat={AR113Value} />
        <Igra background='rgb(159,206,99)' igra='1X-X2' procenat={AT113Value} />
        <Igra background='rgb(159,206,99)' igra='1X-12' procenat={AV113Value} />
        <Igra background='rgb(159,206,99)' igra='X2-1X' procenat={AX113Value} />
        <Igra background='rgb(159,206,99)' igra='X2-X2' procenat={H116Value} />
        <Igra background='rgb(159,206,99)' igra='X2-12' procenat={J116Value} />
        <Igra background='rgb(159,206,99)' igra='12-1X' procenat={L116Value} />
        <Igra background='rgb(159,206,99)' igra='12-X2' procenat={N116Value} />
        <Igra background='rgb(159,206,99)' igra='12-12' procenat={P116Value} />

        <Title background='rgb(177,204,151)' title='HENDIKEPI' />

        <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
          <KategorijeIgre background='rgb(177,204,151)' title='HENDIKEP (-1)' />
          <Igra background='rgb(177,204,151)' igra='H1' procenat={H121Value} />
          <Igra background='rgb(177,204,151)' igra='HX' procenat={J121Value} />
          <Igra background='rgb(177,204,151)' igra='H2' procenat={L121Value} />
        </div>

        <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
          <KategorijeIgre background='rgb(177,204,151)' title='HENDIKEP (-2)' />
          <Igra background='rgb(177,204,151)' igra='H1' procenat={H124Value} />
          <Igra background='rgb(177,204,151)' igra='HX' procenat={J124Value} />
          <Igra background='rgb(177,204,151)' igra='H2' procenat={L124Value} />
        </div>

        <Title background='rgb(202,222,185)' title='MIKS IGRE' />
        <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
          <KategorijeIgre
            background='rgb(202,222,185)'
            title='KONAČAN ISHOD - GOLOVI'
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& D2+'
            procenat={H129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& D3+'
            procenat={J129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1&3+'
            procenat={L129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1&4+'
            procenat={N129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 5+'
            procenat={P129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 1P1+'
            procenat={R129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1&1P 2+'
            procenat={T129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1&2P 1+'
            procenat={V129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 2P 2+'
            procenat={X129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1&1P1+ & 2P 1+'
            procenat={Z129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 0-2'
            procenat={AD129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 0-3'
            procenat={AF129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 0-4'
            procenat={AH129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 2-3'
            procenat={AJ129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 2-4'
            procenat={AL129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 2-5'
            procenat={AN129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 2-6'
            procenat={AP129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 3-4'
            procenat={AR129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 3-5'
            procenat={AT129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 3-6'
            procenat={AV129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 1P 2-3'
            procenat={AX129Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& G2+'
            procenat={H132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2 & G 3+'
            procenat={J132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2 & 3+'
            procenat={L132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2 & 4+'
            procenat={N132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2 & 5+'
            procenat={P132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2 & 1P1+'
            procenat={R132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 1P2+'
            procenat={T132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 2P 1+'
            procenat={V132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 2P2+'
            procenat={X132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2&1P1+ & 2P 1+'
            procenat={Z132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 0-2'
            procenat={AD132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 0-3'
            procenat={AF132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 0-4'
            procenat={AH132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 2-3'
            procenat={AJ132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 2-4'
            procenat={AL132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 2-5'
            procenat={AN132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 2-6'
            procenat={AP132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 3-4'
            procenat={AR132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 3-5'
            procenat={AT132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 3-6'
            procenat={AV132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 2P 2-3'
            procenat={AX132Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='X& 1P 2-3'
            procenat={H135Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='X& 2+'
            procenat={J135Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='X& 2+'
            procenat={L135Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='X& 3+'
            procenat={N135Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='X& 0-2'
            procenat={P135Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 1>'
            procenat={R135Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 1=2'
            procenat={T135Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1& 2>'
            procenat={V135Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 1>'
            procenat={X135Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 1=2'
            procenat={Z135Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2& 2>'
            procenat={AB135Value}
          />
        </div>
        <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
          <KategorijeIgre
            background='rgb(202,222,185)'
            title='KONAČAN ISHOD - GG'
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1X& 2+'
            procenat={H138Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1X& 3+'
            procenat={J138Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1X& 4+'
            procenat={L138Value}
          />
        </div>

        <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
          <KategorijeIgre
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            title='MIKS DUPLA ŠANSA - GOLOVI'
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X& 2+'
            procenat={H141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X& 3+'
            procenat={J141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X& 4+'
            procenat={L141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&0-2'
            procenat={N141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&0-3'
            procenat={P141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&2-3'
            procenat={R141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&1P0-1'
            procenat={T141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&1P2+'
            procenat={V141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&D2+'
            procenat={X141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&1-2'
            procenat={Z141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&1-3'
            procenat={AB141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&2-4'
            procenat={AD141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&2-5'
            procenat={AF141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&3-5'
            procenat={AH141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&3-6'
            procenat={AJ141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&4-6'
            procenat={AL141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&4-6'
            procenat={AN141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&2+'
            procenat={AP141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&4+'
            procenat={AR141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&0-2'
            procenat={AT141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&0-3'
            procenat={AV141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&2-3'
            procenat={AX141Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&0-3'
            procenat={H144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&2-3'
            procenat={J144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&1P0-1'
            procenat={L144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&1P2+'
            procenat={P144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&G2+'
            procenat={R144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&1-2'
            procenat={T144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&1-3'
            procenat={V144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&2-4'
            procenat={X144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&1-3'
            procenat={Z144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&2-4'
            procenat={AB144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&2-5'
            procenat={AD144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&3-5'
            procenat={AF144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&3-6'
            procenat={AH144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&4-6'
            procenat={AJ144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='12&2+'
            procenat={AL144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='12&3+'
            procenat={AN144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='12&0-2'
            procenat={AP144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='12&0-3'
            procenat={AR144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='12&2-3'
            procenat={AT144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&GG'
            procenat={AV144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='12GG'
            procenat={AX144Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&GG'
            procenat={H147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&1>'
            procenat={J147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&1=2'
            procenat={L147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='1X&2>'
            procenat={N147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&1>'
            procenat={P147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&1=2'
            procenat={R147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&2>'
            procenat={T147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&1>'
            procenat={V147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&1=2'
            procenat={X147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='X2&2>'
            procenat={Z147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='12&1>'
            procenat={AB147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='12&1=2'
            procenat={AD147Value}
          />
          <Igra
            background='rgb(202,222,185)'
            background='rgb(202,222,185)'
            igra='12&2>'
            procenat={AF147Value}
          />
        </div>
        <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
          <KategorijeIgre background='rgb(202,222,185)' title='ILI - ILI' />
          <Igra
            background='rgb(202,222,185)'
            igra='1v3+'
            procenat={H150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1v4+'
            procenat={J150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1vGG'
            procenat={L150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2v3+'
            procenat={N150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2v4+'
            procenat={P150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2vGG'
            procenat={R150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='D4+vG4+'
            procenat={T150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1v1P2+'
            procenat={V150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='2v2P2+'
            procenat={X150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1-1vX-1'
            procenat={Z150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1-1vX-X'
            procenat={AB150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1-1v2-2'
            procenat={AD150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='1P2+v2P2+'
            procenat={AF150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='GG1vNG2'
            procenat={AJ150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='NG1vGG2'
            procenat={AL150Value}
          />
          <Igra
            background='rgb(202,222,185)'
            igra='GG1vGG2'
            procenat={AN150Value}
          />
        </div>
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(202,222,185)'
          title='MIKS I P- II P (GG-NEGG)'
        />
        <Igra
          background='rgb(202,222,185)'
          igra='P1&GG1'
          procenat={H153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='PX&GG1'
          procenat={J153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='P2&GG1'
          procenat={L153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='P1&NG1'
          procenat={N153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='PX&NG1'
          procenat={P153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='P2&NG1'
          procenat={R153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2P1&GG2'
          procenat={T153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2PX&GG2'
          procenat={X153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2P2&GG2'
          procenat={Z153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2P1&NG2'
          procenat={AB153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2PX&NG2'
          procenat={AD153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2P2&NG2'
          procenat={AF153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='GG1&NG2'
          procenat={AH153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='NG1&NG2'
          procenat={AJ153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='GG1&GG2'
          procenat={AN153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='GG&1P3+'
          procenat={AP153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='GG&2P3+'
          procenat={AR153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='GG2&2P3+'
          procenat={AT153Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='GG2&1P3+'
          procenat={AV153Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre
          background='rgb(202,222,185)'
          title='MIKS POLUVREME - GOLOVI'
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&GG'
          procenat={H156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&NG'
          procenat={J156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&2+'
          procenat={L156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&3+'
          procenat={N156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&4+'
          procenat={P156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&1P2+'
          procenat={R156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&2P2+'
          procenat={T156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&0-2'
          procenat={V156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&0-3'
          procenat={X156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&2-3'
          procenat={Z156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&2-4'
          procenat={AB156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&2-5'
          procenat={AD156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&3-4'
          procenat={AF156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&3-5'
          procenat={AH156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&3-6'
          procenat={AJ156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-1&HP1'
          procenat={AL156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&GG'
          procenat={AN156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&NG'
          procenat={AP156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&2+'
          procenat={AR156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&3+'
          procenat={AT156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&4+'
          procenat={AV156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&1P2+'
          procenat={AX156Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&2P2+'
          procenat={H159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&0-2'
          procenat={J159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&0-3'
          procenat={L159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&2-3'
          procenat={N159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&2-4'
          procenat={P159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&2-5'
          procenat={R159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&3-4'
          procenat={T159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&3-5'
          procenat={V159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&3-6'
          procenat={X159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-2&HP2'
          procenat={Z159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&GG'
          procenat={AB159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&NG'
          procenat={AD159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&2+'
          procenat={AF159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&3+'
          procenat={AH159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&4+'
          procenat={AJ159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&1P2+'
          procenat={AL159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&2P2+'
          procenat={AN159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&0-1'
          procenat={AP159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&0-2'
          procenat={AR159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&0-3'
          procenat={AT159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&2-3'
          procenat={AV159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&2-4'
          procenat={AX159Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&2-5'
          procenat={H162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&3-4'
          procenat={J162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&3-5'
          procenat={L162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&3-6'
          procenat={N162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-1&HP1'
          procenat={P162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&GG'
          procenat={R162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&NG'
          procenat={T162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&2+'
          procenat={V162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&3+'
          procenat={X162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&4+'
          procenat={Z162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&1P2+'
          procenat={AB162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&2P2+'
          procenat={AD162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&0-1'
          procenat={AH162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&0-2'
          procenat={AJ162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&0-3'
          procenat={AL162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&2-3'
          procenat={AN162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&2-4'
          procenat={AP162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&2-5'
          procenat={AR162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&3-4'
          procenat={AT162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-X&3-5'
          procenat={AV162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&3-6'
          procenat={AX162Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-2&HP2'
          procenat={H165Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-X&2+'
          procenat={J165Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-X&3+'
          procenat={L165Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='X-X&0-2'
          procenat={N165Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='1-2&5+'
          procenat={P165Value}
        />
        <Igra
          background='rgb(202,222,185)'
          igra='2-1&5+'
          procenat={R165Value}
        />
      </div>

      <Title background='rgb(229,238,220)' title='TAČAN REZULTAT' />

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre background='rgb(229,238,220)' title='TAČAN REZULTAT' />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 0:0'
          procenat={H170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 1:0'
          procenat={J170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 0:1'
          procenat={L170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 1:1'
          procenat={N170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 2:0'
          procenat={P170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 0:2'
          procenat={R170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 2:1'
          procenat={T170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 1:2'
          procenat={V170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 3:0'
          procenat={X170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 0:3'
          procenat={Z170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 2:2'
          procenat={AB170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 4:0'
          procenat={AD170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 0:4'
          procenat={AF170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 3:1'
          procenat={AH170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 1:3'
          procenat={AJ170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 3:2'
          procenat={AL170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 2:3'
          procenat={AN170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 4:1'
          procenat={AP170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 1:4'
          procenat={AR170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 5:0'
          procenat={AT170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 0:5'
          procenat={AV170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 3:3'
          procenat={AX170Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 4:2'
          procenat={H173Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 2:4'
          procenat={J173Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 5:1'
          procenat={L173Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='TR 1:5'
          procenat={N173Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='OSTALO'
          procenat={T173Value}
        />
      </div>

      <div style={{ flexWrap: 'wrap' }} className={classes.kategorija}>
        <KategorijeIgre background='rgb(229,238,220)' title='TAČAN REZULTAT' />
        <Igra background='rgb(229,238,220)' igra='P 0:0' procenat={H176Value} />
        <Igra background='rgb(229,238,220)' igra='P 1:0' procenat={J176Value} />
        <Igra background='rgb(229,238,220)' igra='P 0:1' procenat={L176Value} />
        <Igra background='rgb(229,238,220)' igra='P 1:1' procenat={N176Value} />
        <Igra background='rgb(229,238,220)' igra='P 2:0' procenat={P176Value} />
        <Igra background='rgb(229,238,220)' igra='P 0:2' procenat={R176Value} />
        <Igra background='rgb(229,238,220)' igra='P 2:1' procenat={T176Value} />
        <Igra background='rgb(229,238,220)' igra='P 1:2' procenat={V176Value} />
        <Igra background='rgb(229,238,220)' igra='P 3:0' procenat={X176Value} />
        <Igra background='rgb(229,238,220)' igra='P 0:3' procenat={Z176Value} />
        <Igra
          background='rgb(229,238,220)'
          igra='P 2:2'
          procenat={AB176Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='P 3:1'
          procenat={AD176Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='P 1:3'
          procenat={AF176Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='P 3:2'
          procenat={AH176Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='P 2:3'
          procenat={AJ176Value}
        />
        <Igra
          background='rgb(229,238,220)'
          igra='P 3:3'
          procenat={AL176Value}
        />
        <Igra background='rgb(229,238,220)' igra='OSTALO' procenat={AN176()} />
      </div>
    </div>
  )
}

export default FudbalSveIgre
