import { ReactCalculator } from "simple-react-calculator";
import { useNavigate } from "react-router-dom";
import classes from "./grupa2.module.css";

const Grupa2 = ({
  imgText,
  imgBackgroundColorDark,
  imgBackgroundColorLight,
  imgFontColor,
  label1,
  label2,
  label3,
  label4,
  label5,
  label6,
}) => {
  const navigate = useNavigate();

  const handleOsveziKalkulator = () => {
    window.location.reload();
  };

  return (
    <div className={classes.outherContainer}>
      <div className={classes.container}>
        <div className={classes.leftContainer}>
          <div
            style={{
              height: 54,
              background: `linear-gradient(${imgBackgroundColorDark}, ${imgBackgroundColorLight})`,
              color: imgFontColor,
              border: "3px solid black",
              borderRadius: 8,
              textAlign: "center",
              fontSize: 32,
              fontWeight: "bolder",
            }}
          >
            <p
              style={{
                margin: 12,
              }}
            >
              {imgText}
            </p>
          </div>
          <div className={classes.inputFields}>
            <div className={classes.left}>
              <div className={classes.partOne}>
                <input
                  className={classes.input}
                  type="text"
                  placeholder={label1}
                />
                <input
                  className={classes.input}
                  type="text"
                  placeholder={label2}
                />
              </div>
              <div className={classes.partTwo}>
                <input
                  className={classes.input}
                  type="text"
                  placeholder={label3}
                />
              </div>
            </div>
            <div className={classes.right}>
              <div className={classes.partOne}>
                <input
                  className={classes.input}
                  type="text"
                  placeholder={label4}
                />
                <input
                  className={classes.input}
                  type="text"
                  placeholder={label5}
                />
              </div>
              <div className={classes.partTwo}>
                <input
                  className={classes.input}
                  type="text"
                  placeholder={label6}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={classes.calculator}>
          <ReactCalculator />
        </div>
      </div>
      <div className={classes.footer}>
        <a href="#" className={classes.tooltip}>
          GDE DA PRONAĐEM PODATKE ZA UNOS ?
          <span>
            POSETI SAJT BILO KOJE KLADIONICE ILI BILO KOJI DRUGI SAJT KOJI
            OBRAĐUJE KVOTE I REZULTATE UTAKMICA. ZA DETALJNIJA OBJAŠNJENJA
            KLIKNI NA ''OPIS KALKULATORA''.
          </span>
        </a>
        <a href="#" className={classes.tooltip}>
          INSTRUKCIJE ZA UNOS PODATAKA
          <span>
            ZA UNOS PODATAKA KORISTI BELA POLJA. UKOLIKO POLJA ODVAJA PRIKAZANA
            TAČKA UNESI BROJ I U LEVO I U DESNO POLJE OD TAČKE (NPR. 2 = LEVO .
            DESNO = 00). UKOLIKO NEMA PRIKAZANE TAČKE UNESI DECIMALNI BROJ UZ
            UPIS TAČKE (NPR. 2.00). POLJA OBELEŽENA CRVENOM ZVEZDICOM * SU
            OBAVEZNA POLJA ZA UNOS U CELINI UNOSA BROJA ILI DECIMALNOG BROJA.
          </span>
        </a>
        <a onClick={() => handleOsveziKalkulator()}>
          KLIKNI I OSVEŽI KALKULATOR
        </a>
        <a
          onClick={() =>
            navigate("/bet_kalkulatori_tutorijal/6488fa2538a314f4e3850711")
          }
        >
          UBRZAJ RAD SA KALKULATORIMA !
        </a>
      </div>
    </div>
  );
};

export default Grupa2;
