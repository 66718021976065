import { useNavigate } from "react-router-dom";
import betkoNatpis from "../images/betkoNatpis.png";
import left from "./assets/left.png";
import right from "./assets/right.png";
import classes from "./twoButtons.module.css";
import { Recommendations } from "disqus-react";
import React, { useRef, useEffect, useState, useContext } from "react";
import Axios from "axios";
import Slider from "react-slick";
import { Swiper, SwiperSlide } from "swiper/react";
import { IoMdArrowDropleft, IoMdArrowDropright } from "react-icons/io";
import {
  Autoplay,
  Navigation,
  FreeMode,
  Keyboard,
  Mousewheel,
  Thumbs,
  EffectFade,
} from "swiper/modules";
import ReactPlayer from "react-player";
import AuthContext from "../context/AuthProvider";

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const handleContextMenu = (event) => {
  event.preventDefault();
};

const TwoButtons = () => {
  const navigate = useNavigate();
  const [recommendations, setRecommendations] = useState([]);

  const { refetch } = useContext(AuthContext)

  const handleClick = (tip) => {
    if (tip === "bet kurs tutorijal") {
      navigate("/bet_kurs_tutorijal");
    }
    if (tip === "bet kalkulatori tutorijal") {
      navigate("/bet_kalkulatori_tutorijal");
    }
  };

  const handleCardClick = (item) => {
    if (item.type === "infoblock") {
      navigate(`/info_blok/${item._id}`);
    } else if (item.type === "betportals") {
      navigate(`/bet_portal/${item._id}`);
    } else if (item.type === "statistics") {
      navigate(`/statistika/${item._id}`);
    } else if (item.type === "betcourse") {
      navigate(`/bet_kurs/${item._id}`);
    } else if (item.type === "betcoursenew") {
      navigate(`/bet_kurs_novo/${item._id}`);
    } else if (item.type === "yoursuggestions") {
      navigate(`/naši_predlozi/${item._id}`);
    } else if (item.type === "tutorials") {
      navigate(`/bet_kalkulatori_tutorijal/${item._id}`);
    }
  };

  useEffect(() => {
    fetchRecommendations();
  }, [refetch]);

  const fetchRecommendations = async () => {
    const items = await Axios.get(`${SERVER_URL}/posts-latestcomments`);

    setRecommendations(items.data.items);
  };

  const swiperRef = useRef(null);

  const goToPrevSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const goToNextSlide = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  return (
    <div className={classes.btnContainers}>
      <div
        className={classes.btn1}
        onClick={() => {
          handleClick("bet kurs tutorijal");
        }}
      >
        <p>OPIS BET KURSA</p>
      </div>
      {/* <Recommendations shortname="betkotip-com" /> */}
      {/* <img src={betkoNatpis} alt="natpis" className={cl/asses.image} /> */}
      <section className={classes.arrowcont}>
        <img
          className={classes.arrows}
          src={left}
          onClick={goToPrevSlide}
        ></img>
      </section>
      <div className={classes.recommendations}>
        <div className={classes.swipercont}>
          <Swiper
            className={classes.swiperclass}
            spaceBetween={30}
            slidesPerView={6}
            onSwiper={(swiper) => {
              swiperRef.current = swiper;
            }}
            direction="horizontal"
          // onSlideChange={() => console.log("slide change")}
          // onSwiper={(swiper) => console.log(swiper)}
          >
            {recommendations.map((item, index) => {
              return (
                <SwiperSlide
                  key={index}
                  onClick={(e) => {
                    handleCardClick(item);
                  }}
                  className={classes.card}
                >
                  {item && item.featureVideo && item.featureVideo.url ? (
                    <ReactPlayer
                      url={`${SERVER_URL}/${item.featureVideo?.url}`}
                      playing={true} // Enables autoplay
                      muted={true} // Mutes the video
                      loop
                      onContextMenu={handleContextMenu}
                      height={"120px"}
                      width={"150px"}
                    />
                  ) : (
                    <img src={`${SERVER_URL}/${item?.featureImage?.url}`}></img>
                  )}
                  <div>
                    <h2>{item.title}</h2>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>

          <div className={classes.latestCommentedLeft}>
            <IoMdArrowDropleft style={{ fontSize: '25px' }} />
            <span>ZADNJE KOMENTARISANO</span>
            <IoMdArrowDropright style={{ fontSize: '25px' }} />
          </div>

          <div className={classes.latestCommentedRight}>
            <IoMdArrowDropleft style={{ fontSize: '25px' }} />
            <span>ZADNJE KOMENTARISANO</span>
            <IoMdArrowDropright style={{ fontSize: '25px' }} />
          </div>
        </div>
      </div>

      <section className={classes.arrowcont}>
        <img
          onClick={goToNextSlide}
          className={classes.arrows}
          src={right}
        ></img>
      </section>

      <div
        className={classes.btn2}
        onClick={() => {
          handleClick("bet kalkulatori tutorijal");
        }}
      >
        <p>OPIS KALKULATORA</p>
      </div>
    </div>
  );
};

export default TwoButtons;
