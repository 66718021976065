import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import axios from "../config/axios";
import Spinner from "../components/Spinner";
import parse from "html-react-parser";
import classes from "./betKalkulatoriTutorijalSinglePage.module.css";
import prethodna from "./assets/prethodna.png";
import sledeca from "./assets/sledeca.png";
import dole from "./assets/dole.png";
import gore from "./assets/gore.png";
import vasipredlozistrelica from "./assets/vasipredlozistrelica.png";
import React from "react";
import ReactPlayer from "react-player";

const handleContextMenu = (event) => {
  event.preventDefault();
};

const SERVER_URL = process.env.REACT_APP_SERVER_URL;

const BetKalkulatoriTutorijalSinglePage = () => {
  const { id } = useParams();

  const [data, setData] = useState({});
  const [previousPost, setPreviousPost] = useState({});
  const [nextPost, setNextPost] = useState({});
  const [threeBefore, setThreeBefore] = useState({});
  const [threeNext, setThreeNext] = useState({});
  const [chatImage, setChatImage] = useState();

  const fetchData = async () => {
    const { data } = await axios.get(`/tutorial/${id}`);

    // console.log(data);

    setData(data.post);
  };

  useEffect(() => {
    fetchData();
    fetchChatImage();
  }, [id]);

  const fetchChatImage = async () => {
    const img = await axios.get(`/chat-image/tutorials`);

    setChatImage(img.data);
  };
  useEffect(() => {
    fetchPreviosuCard();
    fetchNextCard();
    fetchThreeNextCard();
    fetchThreeBeforeCard();
  }, [data]);

  const fetchPreviosuCard = async () => {
    // console.log(data._id);
    try {
      const previousPost = await axios.get(
        `/tutorials-index?id=${data._id}&&index=-1`
      );
      setPreviousPost(previousPost.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchNextCard = async () => {
    try {
      // console.log(data._id);
      const nextCard = await axios.get(
        `/tutorials-index?id=${data._id}&&index=+1`
      );
      setNextPost(nextCard.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchThreeNextCard = async () => {
    try {
      // console.log(data._id);
      const threenextCard = await axios.get(
        `/tutorials-index?id=${data._id}&&index=+3`
      );

      setThreeNext(threenextCard.data);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchThreeBeforeCard = async () => {
    try {
      // console.log(data._id);
      const threebeforecard = await axios.get(
        `/tutorials-index?id=${data._id}&&index=-3`
      );

      setThreeBefore(threebeforecard.data);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.mainCenter}>
      {!data ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.content}>
          <div className={classes.title}>{data.title?.toUpperCase()}</div>

          <div className={classes.firstRow}>
            {data && data.featureVideo && data.featureVideo.url ? (
              <div className={classes.featureVideoDiv}>
                <ReactPlayer
                  url={`${SERVER_URL}/${data.featureVideo?.url}`}
                  playing={true} // Enables autoplay
                  muted={true} // Mutes the video
                  loop
                  width="337px"
                  height="267px"
                  onContextMenu={handleContextMenu}
                />
              </div>
            ) : (
              <img
                className={classes.featureImage}
                src={`${SERVER_URL}/${data.featureImage?.url}`}
              ></img>
            )}

            {data && data.marketingVideo && data.marketingVideo.url ? (
              <div className={classes.featureVideoDiv}>
                <a
                  className={classes.marketingImageATag}
                  href={data.marketingLink}
                  target="_blank"
                >
                  <ReactPlayer
                    url={`${SERVER_URL}/${data.marketingVideo?.url}`}
                    playing={true} // Enables autoplay
                    muted={true} // Mutes the video
                    loop
                    width="337px"
                    height="267px"
                    onContextMenu={handleContextMenu}
                  />
                </a>
              </div>
            ) : (
              <a
                className={classes.marketingImageATag}
                href={data.marketingLink}
                target="_blank"
              >
                <img
                  className={classes.marketingImage}
                  src={`${SERVER_URL}/${data.marketingImage?.url}`}
                ></img>
              </a>
            )}
            <a
              className={classes.marketingImageATag}
              href={chatImage?.link}
              target="_blank"
            >
              <img
                className={classes.marketingImage}
                src={`${SERVER_URL}/${chatImage?.image?.url}`}
              ></img>
            </a>
          </div>

          <div className={classes.navigationRow}>
            {previousPost && previousPost._id && (
              <a href={`/bet_kalkulatori_tutorijal/${previousPost._id}`}>
                <img className={classes.navigationImage} src={prethodna}></img>
              </a>
            )}

            {threeNext && threeNext._id && (
              <a href={`/bet_kalkulatori_tutorijal/${threeNext._id}`}>
                <img className={classes.navigationImage} src={gore}></img>
              </a>
            )}

            <a href={`/vaši_predlozi`}>
              <img
                className={classes.navigationImage}
                src={vasipredlozistrelica}
              ></img>
            </a>

            {threeBefore && threeBefore._id && (
              <a href={`/bet_kalkulatori_tutorijal/${threeBefore._id}`}>
                <img className={classes.navigationImage} src={dole}></img>
              </a>
            )}

            {nextPost && nextPost._id && (
              <a href={`/bet_kalkulatori_tutorijal/${nextPost._id}`}>
                <img className={classes.navigationImage} src={sledeca}></img>
              </a>
            )}
          </div>

          <div className={classes.contentRow}>
            {data &&
              data.items &&
              data.items.length > 0 &&
              data.items.map((item, index) => {
                if (item.type === "text") {
                  return (
                    <div
                      style={{
                        color: item.color,
                        fontWeight: item.bold ? "bolder" : false,
                        fontStyle: item.italic ? "italic" : false,
                        fontSize: item.fontSize ? `${item.fontSize}px` : "30px",
                        marginTop: item.marginTop,
                        marginBottom: item.marginBottom,
                      }}
                    >
                      {item.description}
                    </div>
                  );
                }
                if (item.type === "video") {
                  // console.log(item);
                  return (
                    <video
                      width="720"
                      height="360"
                      controls
                      controlsList="nodownload"
                      style={{ marginBottom: "10px" }}
                    >
                      <source
                        src={`${SERVER_URL}/${item.video.url}`}
                        type="video/mp4"
                      />
                    </video>
                  );
                }
                if (item.type === "img") {
                  return (
                    <a
                      target="_blank"
                      href={item?.imageLink}
                      className={classes.itemImageATag}
                    >
                      <img
                        className={classes.itemImage}
                        src={`${SERVER_URL}/${item.image?.url}`}
                      ></img>
                    </a>
                  );
                }
              })}
          </div>

          <div className={classes.textField}>
            {data.textField?.toUpperCase()}
          </div>
        </div>
      )}
    </div>
  );
};

export default BetKalkulatoriTutorijalSinglePage;
