// import logoImage from "../icons/betko_logo.png";
import classes from './footer.module.css'
import { useEffect, useState, createContext, useContext } from 'react'
import Axios from 'axios'
import { useLocation, useNavigate } from 'react-router-dom'
import AppContext from '../context/AppContext'

const Footer = () => {
  const SERVER_URL = process.env.REACT_APP_SERVER_URL
  const [location, setLocation] = useState('world')
  const [footerImages, setFooterImages] = useState([])
  const [backgroundIndex, setBackgroundIndex] = useState(0)
  const { setOpenLiveComments } = useContext(AppContext)
  const navigate = useNavigate()
  const windowLocation = useLocation()

  const handleIP = async () => {
    // const res = await Axios.get("https://api.ipify.org/?format=json");
    // const res2 = await Axios.get(
    //   `https://pro.ip-api.com/json/${res.data.ip}?key=uVgMOy8R7sWnktl`
    // );

    // TO DO:
    setLocation(/*res2.data.countryCode*/ 'world')
    localStorage.setItem('countryCode', /*res2.data.countryCode*/ 'world')
  }

  useEffect(() => {
    handleIP()
  })

  useEffect(() => {
    fetchData()
  }, [location])

  useEffect(() => {
    fetchData()
  }, [])

  const fetchData = async () => {
    if (location) {
      const res = await Axios.get(
        `${SERVER_URL}/footer-images-code/${location}`
      )

      setFooterImages(res.data)
    } else {
      const res = await Axios.get(`${SERVER_URL}/footer-images`)

      if (res && res.data && res.data.items) {
        setFooterImages(res.data.items)
      }
    }
  }

  useEffect(() => {
    let timeout

    if (footerImages && footerImages.length > 0) {
      timeout = setTimeout(() => {
        setBackgroundIndex((prevIndex) => {
          return (prevIndex + 1) % footerImages.length
        })
      }, footerImages[backgroundIndex].duration * 1000)
      return () => clearTimeout(timeout)
    }

    // Clear the timeout when the component is unmounted
  }, [backgroundIndex, footerImages])

  return (
    <div className={classes.footer}>
      <a target='_blank' href={footerImages[backgroundIndex]?.url}>
        <img
          className={classes.img}
          src={`${SERVER_URL}/${footerImages[backgroundIndex]?.image?.url}`}
        ></img>
      </a>

      {windowLocation.pathname === '/rezultati_uzivo' ? (
        <div className={classes.liveResults}>
          <div>
            <button onClick={() => setOpenLiveComments((prev) => !prev)}>
              KLIKNI DA ZAJEDNO KOMENTARIŠEMO UTAKMICE
            </button>
          </div>
        </div>
      ) : windowLocation?.pathname?.includes('bet_kalkulatori') ? null : (
        <div className={classes.buttonsContainer}>
          <div>
            <button
              onClick={() =>
                navigate('/naši_predlozi/655a9ed4e1a1ab2a0a7a0bde')
              }
            >
              KLIK - TIKET MANIA
            </button>
          </div>

          <div>
            <button
              onClick={() => navigate('/bet_portal/653e3c6e52a098b10de970f1')}
            >
              KLIK - UZ PIVCE
            </button>
          </div>

          <div>
            <button
              onClick={() =>
                navigate('/naši_predlozi/655cda68e0dc5f730f3071d7')
              }
            >
              KLIK - STRANICE TIPUJU
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default Footer
